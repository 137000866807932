import React, { Component } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

const LDAPUserPassword = observer(
	class LDAPUserPassword extends Component {

		constructor(props) {
			super(props);

			this.passwordRef = React.createRef();
		}

		componentDidMount() {
			uTrackStore.clearResult();

			runInAction(() => {
				uTrackStore.ldap.password = '';
			});

			const { username } = this.props.match.params;

			uTrackStore.loadLDAPUser(username).then(result => {
				if (!result) {
					uTrackStore.preserveResult();

					this.props.history.push('/ldap/users');
				}
			});

			if (this.passwordRef.current) {
				this.passwordRef.current.focus();
			}
		}

		handlePasswordChange = e => {
			uTrackStore.ldap.password = e.target.value;
		}

		handleChangePasswordClick = async e => {
			e.preventDefault();

			const { username } = this.props.match.params;

			await uTrackStore.changeLDAPUserPassword(username);

			const { result: { success } } = uTrackStore;

			if (success) {
				uTrackStore.preserveResult();

				this.props.history.push('/ldap/users');
			}
		}

		render() {
			const { ldap: { password, working } } = uTrackStore;

			return (
				<section className="section account">
					<div class="section__header">
						<h2 className="section__title">Change LDAP users's password</h2>
					</div>

					<div className="section__content">
						<p className="section__text">To change LDAP user's password please fill out the form below.</p>

						<ResultMessage />

						<form className="form">
							<div className="form__group">
								<label htmlFor="password" className="form__label">New password</label>
								<input type="password" id="password" className="form__input form__input--short" placeholder="New password ..." onChange={this.handlePasswordChange} value={password} />
							</div>

							<div className="form__buttons">
								<button disabled={working || !password} className="button button--primary button--loader" onClick={this.handleChangePasswordClick}>
									{working ? (
										<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
									) : null}
									Change password
								</button>
							</div>
						</form>
					</div>
				</section>
			);
		}

	}
);

export default LDAPUserPassword;
