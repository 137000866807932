import React, { PureComponent } from 'react';

import { IoMdLock } from 'react-icons/io';

class HTTP403 extends PureComponent {

	render() {
		return (
			<div className="http-error">
				<div className="http-error__content">
					<IoMdLock className="http-error__icon" />

					<h2 className="http-error__title">HTTP 403</h2>

					<p className="http-error__text">Your account does not have sufficient privileges to access this page.</p>
				</div>
			</div>
		);
	}

}

export default HTTP403;
