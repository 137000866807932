import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

const TaskExecute = observer(
	class TaskExecute extends Component {

		componentDidMount() {
			const { id } = this.props.match.params;

			uTrackStore.loadTask(id).then(result => {
				if (!result) {
					uTrackStore.preserveResult();

					this.props.history.push('/tasks');
				}
			});

			uTrackStore.loadServers();
		}

		handleServerChange = e => {
			const selectedOptions = e.target.selectedOptions;

			uTrackStore.tasks.serversToRunTaskOn = Array.from(selectedOptions, (item) => item.value);
		}

		handleSubmitClick = async e => {
			e.preventDefault();

			const { id } = this.props.match.params;
			
			await uTrackStore.executeTaskAsync(id);

			uTrackStore.loadTasksStatus();

			const { result: { success } } = uTrackStore;

			if (success) {
				uTrackStore.preserveResult();

				this.props.history.push('/tasks');
			}
		}

		render() {
			const { servers: { list: serverList, loading: serversLoading }, tasks: { name, serversToRunTaskOn, loading, working } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Execute task</h2>
					</div>

					<div className="section__content">
						<p className="section__text">To execute this task please fill out the form below.</p>

						<ResultMessage />

						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading task, please wait ...
							</p>
						) : (
							<form className="form">
								<div className="form__group">
									<label className="form__label">Task</label>
									<p className="form__static">{name}</p>
								</div>

								{serversLoading ? (
									<p className="section__text section__text--loader">
										<Loader className="section__text-loader" size="tiny" />
										Loading servers, please wait ...
									</p>
								) : (
									serverList.length > 0 ? (
										<div className="form__group">
											<label htmlFor="serversToRunTaskOn" className="form__label">Run on servers</label>
											<select size={Math.min(serverList.length, 5)} id="serversToRunTaskOn" className="form__select form__input--short" value={serversToRunTaskOn} multiple={true} onChange={this.handleServerChange}>
												{serverList.map(s => (
													<option key={s.id} value={s.id}>{s.hostname ? s.hostname : '(empty)'}</option>
												))}
											</select>
										</div>
									) : (
										<p className="section__text">There are no servers to run this task on.</p>
									)
								)}

								<div className="form__buttons">
									<button disabled={working || serversToRunTaskOn.length === 0} className="button button--primary button--loader" onClick={this.handleSubmitClick}>
										{working ? (
											<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
										) : null}
										Execute task
									</button>
								</div>
							</form>
						)}
					</div>
				</section>
			);
		}

	}
);

export default TaskExecute;
