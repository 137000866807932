import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import moment from 'moment';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';
import { Link } from 'react-router-dom';
import Config from '../../config/Config';

import { IoMdEye, IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';

const TaskLog = observer(
	class TaskLog extends Component {

		componentDidMount() {
			uTrackStore.tasks.page = 0;

			uTrackStore.loadTaskLogGroups();
		}

		handlePrevPage = () => {
			const { tasks: { loading } } = uTrackStore;

			if (!loading) {
				uTrackStore.tasks.page--;

				uTrackStore.loadTaskLogGroups();
			}
		}

		handleNextPage = () => {
			const { tasks: { loading } } = uTrackStore;

			if (!loading) {
				uTrackStore.tasks.page++;

				uTrackStore.loadTaskLogGroups();
			}
		}

		render() {
			const { tasks: { taskLogGroups: { content, total }, page, loading } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Task log groups</h2>
					</div>

					<ResultMessage />

					{loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading task log groups, please wait ...
							</p>
						</div>
					) : (
						content.length === 0 ? (
							<div className="section__content">
								<p className="section__text">There are no task log groups to show.</p>
							</div>
						) : (
							<Fragment>
								<div className="section__table">
									<table className="table">
										<thead className="table__head">
											<tr>
												<th>Name</th>
												<th>Created / Finished</th>
												<th>Finished</th>
												<th>Viewed</th>
												<th className="table__cell--least-space">Action</th>
											</tr>
										</thead>

										<tbody>
											{content.map(g => (
												<tr key={g.id}>
													<td>{g.taskName}</td>
													<td>
														<span className="table__muted">{moment(g.createdAt).format('DD/MM/YY @ HH:mm')}</span>
														<br />
														<span className="table__muted">{moment(g.finishedAt).format('DD/MM/YY @ HH:mm')}</span>
													</td>
													<td>{g.finished ? 'Yes' : 'No'}</td>
													<td>{g.viewed ? 'Yes' : 'No'}</td>
													<td className="table__cell--least-space table__cell--buttons">
														<Link to={`/tasks/log/view/${g.id}`} className="button button--small button--primary button--icon button--icon-left">
															<IoMdEye className="button__icon" /> View
														</Link>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>

								<div className="pagination">
									<button disabled={page === 0} className="button button--secondary button--icon button--icon-left" onClick={this.handlePrevPage}>
										<IoMdArrowBack className="button__icon" />
										Previous page
									</button>

									<div className="pagination__current">{page + 1} / {Math.ceil(total / Config.PAGING_PER_PAGE)}</div>

									<button disabled={page + 1 === Math.ceil(total / Config.PAGING_PER_PAGE)} className="button button--secondary button--icon button--icon-right" onClick={this.handleNextPage}>
										Next page
										<IoMdArrowForward className="button__icon" />
									</button>
								</div>
							</Fragment>
						)
					)}
				</section>
			);
		}

	}
);

export default TaskLog;
