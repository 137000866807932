import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Loader extends PureComponent {

	render() {
		const { size, foreground, background, className, ...rest } = this.props;

		return (
			<span className={'loader loader--' + size + (className ? ` ${className}` : '')} {...rest}>
				<span className="loader__circle" style={{ borderColor: background }} />
				<span className="loader__spinner" style={{ borderColor: `${foreground} transparent transparent` }} />	
			</span>
		);
	}

}

Loader.defaultProps = {
	size: 'medium',
	foreground: '#767676',
	background: 'rgba(0, 0, 0, 0.1)'
};

Loader.propTypes = {
	size: PropTypes.oneOf(['micro', 'tiny', 'small', 'medium', 'large']),
	foreground: PropTypes.string,
	background: PropTypes.string,
	className: PropTypes.string
};

export default Loader;
