import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

const AccountUpdate = observer(
	class AccountUpdate extends Component {

		constructor(props) {
			super(props);

			this.firstNameRef = React.createRef();
		}

		componentDidMount() {
			uTrackStore.loadAccountDetails();

			if (this.firstNameRef.current) {
				this.firstNameRef.current.focus();
			}
		}

		handleFirstNameChange = e => {
			uTrackStore.user.details.firstName = e.target.value;
		}

		handleLastNameChange = e => {
			uTrackStore.user.details.lastName = e.target.value;
		}

		handleEmailChange = e => {
			uTrackStore.user.details.email = e.target.value;
		}

		handlePhoneNumberChange = e => {
			uTrackStore.user.details.phoneNumber = e.target.value;
		}

		handleDisplayNameChange = e => {
			uTrackStore.user.details.displayName = e.target.value;
		}

		handlePublicKeyChange = e => {
			uTrackStore.user.details.publicKey = e.target.value;
		}

		handleUpdateAccountDetailsClick = async e => {
			e.preventDefault();

			await uTrackStore.updateAccountDetails();
		}

		render() {
			const { user: { details: { firstName, lastName, email, phoneNumber, displayName, publicKey }, loading, working } } = uTrackStore;

			return (
				<section className="section account">
					<h2 className="section__title">Update account details</h2>

					<div className="section__content">
						<p className="section__text">To update your account details please fill out the form below.</p>

						<ResultMessage />

						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading account details, please wait ...
							</p>
						) : (
							<form className="form">
								<div className="form__row">
									<div className="form__column form__column--short">
										<div className="form__group">
											<label htmlFor="firstName" className="form__label">First name</label>
											<input ref={this.firstNameRef} type="text" id="firstName" className="form__input" placeholder="First name ..." onChange={this.handleFirstNameChange} value={firstName} />
										</div>
									</div>

									<div className="form__column form__column--short">
										<div className="form__group">
											<label htmlFor="lastName" className="form__label">Last name</label>
											<input type="text" id="lastName" className="form__input" placeholder="Last name ..." onChange={this.handleLastNameChange} value={lastName} />
										</div>
									</div>
								</div>

								<div className="form__row">
									<div className="form__column form__column--short">
										<div className="form__group">
											<label htmlFor="email" className="form__label">E-mail</label>
											<input type="email" id="email" className="form__input" placeholder="E-mail ..." onChange={this.handleEmailChange} value={email} />
										</div>
									</div>

									<div className="form__column form__column--short">
										<div className="form__group">
											<label htmlFor="phoneNumber" className="form__label">Phone number</label>
											<input type="text" id="phoneNumber" className="form__input" placeholder="Phone number ..." onChange={this.handlePhoneNumberChange} value={phoneNumber} />
										</div>
									</div>
								</div>

								<div className="form__group">
									<label htmlFor="displayName" className="form__label">Display name</label>
									<input type="text" id="displayName" className="form__input form__input--short" placeholder="Display name ..." onChange={this.handleDisplayNameChange} value={displayName} />
								</div>

								<div className="form__group">
									<label htmlFor="publicKey" className="form__label">Public key (optional)</label>
									<textarea rows="7" id="publicKey" className="form__input form__input--wide form__input--monospace" placeholder="Paste your public key ..." onChange={this.handlePublicKeyChange} value={publicKey} />
								</div>

								<div className="form__buttons">
									<button disabled={!firstName || !lastName || !email || !phoneNumber || !displayName} className="button button--primary button--loader" onClick={this.handleUpdateAccountDetailsClick}>
										{working ? (
											<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
										) : null}
										Update account details
									</button>
								</div>
							</form>
						)}
					</div>
				</section>
			);
		}

	}
);

export default AccountUpdate;
