import React, { Component, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import { intersection } from 'lodash';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import HTTP403 from '../error/HTTP403';

const ProtectedRoute = observer(
	class ProtectedRoute extends Component {

		hasRequiredPrivileges = (requiredRoles = []) => {
			if (!requiredRoles || requiredRoles.length === 0) {
				return true;
			}

			const { authorities : roles } = uTrackStore.user.self;
	
			if (roles.find(r => r.authority === 'ROLE_ADMIN')) {
				return true;
			}
	
			if (intersection(roles.map(r => r.authority), requiredRoles)) {
				return true;
			}
	
			return false;
	
		}

		render() {
			const { isAuthenticated } = uTrackStore;
			const { component, render, roles, ...rest } = this.props;

			if (!isAuthenticated) {
				return <Redirect to="/user/login" />;
			}

			if (isAuthenticated && !this.hasRequiredPrivileges(roles)) {
				return <HTTP403 />;
			}

			return (
				<Fragment>
					<Header />

					<main className="main">
						<Sidebar />
						
						{component ? (
							<Route {...rest} component={component} />
						) : null}

						{render ? (
							<Route {...rest} render={props => render(props)} />
						) : null}
					</main>

					<Footer />
				</Fragment>
			);
		}

	}
);

ProtectedRoute.defaultProps = {
	roles: []
};

export default ProtectedRoute;
