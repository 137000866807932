import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import uTrackStore from '../../stores/uTrackStore';
import MenuItemTasks from '../base/MenuItemTasks';

import uTrackLogo from '../../assets/img/uTrack-logo.png';
import { IoMdPerson, IoMdLogOut } from 'react-icons/io';

const Header = observer(
	class Header extends Component {

		render() {
			const { displayName } = uTrackStore.user.self.user;

			return (
				<header className="header">
					<img className="header__logo" src={uTrackLogo} alt="uTrack" title="uTrack" />

					<ul className="menu">
						<MenuItemTasks />

						<li className="menu__item">
							<NavLink to="/user/account" className="menu__link" activeClassName="menu__link--active">
								<IoMdPerson className="menu__icon" /> Account ({displayName})
							</NavLink>
						</li>

						<li className="menu__item">
							<NavLink to="/user/logout" className="menu__link" activeClassName="menu__link--active">
								<IoMdLogOut className="menu__icon" /> Log out
							</NavLink>
						</li>
					</ul>
				</header>
			);
		}

	}
);

export default Header;
