import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';

import { IoMdArrowForward } from 'react-icons/io';

const LDAPUserView = observer(
	class LDAPUserView extends Component {

		componentDidMount() {
			const { username } = this.props.match.params;

			uTrackStore.loadLDAPUser(username).then(result => {
				if (!result) {
					uTrackStore.preserveResult();

					this.props.history.push('/ldap/users');
				}
			});
		}

		render() {
			const { username } = this.props.match.params;
			const { ldap: { userName, firstName, lastName, email, phoneNumber, displayName, publicKey, loading } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">View LDAP user</h2>
					</div>

					<div className="section__content">
						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading LDAP user, please wait ...
							</p>
						) : (
							<Fragment>
								<div className="section__row">
									<div className="section__column">
										<h3 className="section__subtitle">Details</h3>

										<div className="account-details">
											<dl className="account-details__list">
												<dt className="account-details__list-title">Name</dt>
												<dd className="account-details__list-content">{firstName} {lastName}</dd>

												<dt className="account-details__list-title">Username</dt>
												<dd className="account-details__list-content">{userName}</dd>

												<dt className="account-details__list-title">E-mail</dt>
												<dd className="account-details__list-content">{email}</dd>

												<dt className="account-details__list-title">Phone number</dt>
												<dd className="account-details__list-content">{phoneNumber}</dd>

												<dt className="account-details__list-title">Display name</dt>
												<dd className="account-details__list-content">{displayName}</dd>
											</dl>
										</div>
									</div>

									<div className="section__column">
										<h3 className="section__subtitle">Public key</h3>

										{publicKey ? (
											<pre className="account-details__public-key">{publicKey}</pre>
										) : (
											<p className="section__text">Public key is missing.</p>
										)}
									</div>
								</div>

								<div className="section__buttons">
									<Link to={`/ldap/users/update/${username}`} className="button button--primary button--icon button--icon-right">
										Update LDAP user
										<IoMdArrowForward className="button__icon" />
									</Link>
								</div>
							</Fragment>
						)}
					</div>

					<div className="section__content">
						<h3 className="section__subtitle">Password</h3>

						<div className="section__buttons">
							<Link to={`/ldap/users/password/${username}`} className="button button--primary button--icon button--icon-right">
								Change LDAP user's password
								<IoMdArrowForward className="button__icon" />
							</Link>
						</div>
					</div>
				</section>
			);
		}

	}
);

export default LDAPUserView;
