import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import { Link } from 'react-router-dom';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';
import SettingTypes from '../../types/SettingTypes';
import InlineSettingItem from '../base/InlineSettingItem';
import { runInAction } from 'mobx';

import { IoMdAdd, IoMdCreate, IoMdTrash } from 'react-icons/io';

const Settings = observer(
	class Settings extends Component {

		componentDidMount() {
			uTrackStore.loadSettings();
		}		

		handleDeleteClick = async id => {
			if (window.confirm(`Are you sure you want to delete this settings item?`)) {
				await uTrackStore.deleteSetting(id);

				uTrackStore.loadSettings();
			}
		}

		handleSaveClick = async (id, value) => {
			const { settings: { list } } = uTrackStore;
			const item = list.find(s => s.id === id);

			runInAction(() => {
				uTrackStore.settings.label = item.label;
				uTrackStore.settings.key = item.key;
				uTrackStore.settings.valueType = item.valueType;
				uTrackStore.settings.value = value;
			});

			await uTrackStore.updateSetting(id).then(result => {
				if (result) {
					const item = uTrackStore.settings.list.find(s => s.id === id);
					
					item.value = item.valueType === 'BOOL' ? (value ? 'true' : 'false') : value;
				} else {
					uTrackStore.loadSettings();
				}
			});
		}

		render() {
			const { settings: { list, loading, workingId } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Settings</h2>
					</div>

					<ResultMessage />

					<div className="section__buttons section__buttons--right">
						<Link to="/settings/create" className="button button--primary button--icon button--icon-left">
							<IoMdAdd className="button__icon" /> Create settings item
						</Link>
					</div>

					{loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading setting items, please wait ...
							</p>
						</div>
					) : (
						list.length === 0 ? (
							<div className="section__content">
								<p className="section__text">There are no setting items to show.</p>
							</div>
						) : (
							<div className="section__table">
								<table className="table">
									<thead className="table__head">
										<tr>
											<th className="table__cell--least-space">Label</th>
											<th className="table__cell--least-space">Details</th>
											<th>Value</th>
											<th className="table__cell--least-space">Action</th>
										</tr>
									</thead>

									<tbody>
										{list.map(s => (
											<tr key={s.id}>
												<td className="table__cell--least-space">{s.label}</td>
												<td className="table__cell--least-space">
													<span className="table__muted">
														Key: {s.key}
														<br />
														Type: {SettingTypes.find(t => t.type === s.valueType).name}
													</span>
												</td>
												<td>
													<InlineSettingItem
														id={s.id}
														type={s.valueType}
														value={s.value}
														password={s.key.toLowerCase().indexOf('password') === -1 ? false : true}
														onHandleSaveClick={this.handleSaveClick}
													/>
												</td>
												<td className="table__cell--least-space table__cell--buttons">
													<Link to={`/settings/edit/${s.id}`} className="button button--small button--primary button--icon button--icon-left">
														<IoMdCreate className="button__icon" /> Edit
													</Link>

													<button disabled={workingId === s.id} className="button button--small button--danger button--icon button--icon-left" onClick={() => this.handleDeleteClick(s.id)}>
														{workingId === s.id ? (
															<Loader className="button__loader" size="micro" foreground="#fff" background="rgba(255,255,255,.15)" />
														) : (
															<IoMdTrash className="button__icon" />
														)}
														Delete
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)
					)}
				</section>
			);
		}

	}
);

export default Settings;
