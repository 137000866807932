import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';

import { IoMdCheckmarkCircle, IoMdAlert } from 'react-icons/io';

const ResultMessage = observer(
	class ResultMessage extends Component {

		render() {
			const { center } = this.props;
			const { error, success, suppressed } = uTrackStore.result;

			return (
				!suppressed ? (
					<Fragment>
						{success ? (
							<div className={`message message--success${center ? ' message--center' : ''}`}>
								<IoMdCheckmarkCircle className="message__icon" /> {success}
							</div>
						) : null}

						{error ? (
							<div className={`message message--error${center ? ' message--center' : ''}`}>
								<IoMdAlert className="message__icon" /> {error}
							</div>
						) : null}
					</Fragment>
				) : null
			);
		}

	}
);

export default ResultMessage;
