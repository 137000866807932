import uTrackAPI from "../api/uTrackAPI";
import { decorate, computed, observable, runInAction } from 'mobx';

class uTrackStore {

	// Transport layer
	api;

	// Auth token
	token = undefined;

	// Various ready states for the app
	readyState = {
		user: false
	};

	result = {
		error: '',
		success: '',
		errors: [],
		details: null,

		suppressed: false,

		preserve: false
	};

	user = {
		authenticated: false,
		self: {},

		username: '',
		password: '',

		oldPassword: '',
		newPassword: '',

		details: {},

		loading: false,
		working: false,
	};

	ldap = {
		users: [],

		userName: '',
		password: '',
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		displayName: '',
		publicKey: '',

		preview: false,
		loading: false,
		working: false,
		workingId: undefined
	};

	monitor = {
		withIncidents: [],
		withoutIncidents: [],
		thresholds: {},

		query: '',

		loading: false,
		refreshing: false
	};

	servers = {
		list: [],
		labels: {
			country: '',
			region: '',
			comment: ''
		},

		loading: false,
		working: false,
		workingId: undefined
  };
  
  incidents = {
    loading: false
  }

	tasks = {
		list: [],

		name: '',
		mainScript: '',
		cleanupScript: '',
		rollbackScript: '',
		monitored: 0,
		frequency: 0,
		runOnAll: 0,
		runOnServer: '',

		status: {
			unfinished: 0,
			unviewed: 0
		},

		serversToRunTaskOn: [],

		taskLogGroups: {
			content: [],
			total: 0
		},
		taskLogGroupData: null,

		page: 0,

		loading: false,
		loadingStatus: false,
		working: false,
		workingId: undefined
	};

	settings = {
		list: [],

		label: '',
		key: '',
		valueType: '',
		value: false,

		page: 0,

		loading: false,
		working: false,
		workingId: undefined
	};

	constructor() {
		this.api = new uTrackAPI(this);

		this.init();
	}

	async init() {
		const token = localStorage.getItem('token');

		if (token) {
			this.token = token;
			
			await this.self();
		}

		this.readyState.user = true;
	}

	clearResult() {
		if (!this.result.preserve) {
			runInAction(() => {
				this.result.error = '';
				this.result.success = '';
				this.result.errors = [];
				this.result.details = null;
				this.result.suppressed = false;
			});
		} else {
			this.result.preserve = false;
		}
	}

	preserveResult() {
		this.result.preserve = true;
	}

	async login() {
		const { username, password } = this.user;

		this.clearResult();

		if (username && password) {
			try {
				this.user.working = true;

				const response = await this.api.login(username, password);

				if (response.status === 200) {
					this.token = response.headers.authorization;

					localStorage.setItem('token', this.token);

					if (await this.self()) {
						this.user.username = '';
						this.user.password = '';

						return true;
					} else {
						return false;
					}
				}
			} catch (e) {
				console.debug('Error during `login` call: ' + e);

				this.result.error = 'Access deined, please check your login details and try again.';

				return false;
			} finally {
				this.user.working = false;
			}
		}
	}

	async self() {
		try {
			const response = await this.api.self();

			runInAction(() => {
				this.user.self = response.data;
				this.user.authenticated = true;
			});

			return true;
		} catch (e) {
			console.debug('Error during `self` call: ' + e);

			localStorage.removeItem('token');

			this.user.authenticated = false;

			return false;
		}
	}

	async logout() {
		try {
			await this.api.logout();
		} catch (e) {
			console.debug('Error during `logout` call: ' + e);
		} finally {
			localStorage.removeItem('token');

			runInAction(() => {
				this.token = undefined
				this.user.authenticated = false;
			});
		}
	}

	async loadAccountDetails() {
		try {
			this.user.loading = true;

			const response = await this.api.loadAccountDetails();

			this.user.details = response.data;

			if (this.user.details.publicKey === null) {
				this.user.details.publicKey = '';
			}

			return true;
		} catch (e) {
			console.debug('Error during `loadAccountDetails` call: ' + e);

			this.result.error = 'An error occured while loading your account details, please try again.';

			return false;
		} finally {
			this.user.loading = false;
		}
	}

	async updateAccountDetails() {
		this.clearResult();

		try {
			this.user.working = true;

			await this.api.updateAccountDetails({
				firstName: this.user.details.firstName,
        		lastName: this.user.details.lastName,
        		displayName: this.user.details.displayName,
        		email: this.user.details.email,
        		phoneNumber: this.user.details.phoneNumber,
        		publicKey: this.user.details.publicKey ? this.user.details.publicKey : null
			});

			this.result.success = 'Your account details have been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `updateAccountDetails` call: ' + e);

			this.result.error = 'An error occured while updating your account details, please try again.';

			return false;
		} finally {
			this.user.working = false;
		}
	}

	async changePassword() {
		const { oldPassword, newPassword } = this.user;

		this.clearResult();

		if (oldPassword && newPassword) {
			try {
				this.user.working = true;

				await this.api.changePassword({
					oldPassword: this.user.oldPassword,
					newPassword: this.user.newPassword
				});

				runInAction(() => {
					this.user.oldPassword = '';
					this.user.newPassword = '';

					this.result.success = 'Your password has been updated successfully.';
				});

				return true;
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.result.error = 'Please make sure that the old password is correct.';
				} else {
					console.debug('Error during `changePassword` call: ' + e);

					this.result.error = 'An error occured while updating your password, please try again.';
				}
				
				return false;
			} finally {
				this.user.working = false;
			}
		}
	}

	async loadLDAPUsers() {
		try {
			this.ldap.loading = true;

			const response = await this.api.loadLDAPUsers();

			this.ldap.users = response.data;

			return true;
		} catch (e) {
			console.debug('Error during `loadLDAPUsers` call: ' + e);

			this.result.error = 'An error occured while loading LDAP users, please try again.';
				
			return false;
		} finally {
			this.ldap.loading = false;
		}
	}

	async loadLDAPUser(username) {
		let user = this.ldap.users.find(u => u.username === username);

		if (!user) {
			await this.loadLDAPUsers();

			user = this.ldap.users.find(u => u.username === username);
		}

		if (user) {
			runInAction(() => {
				this.ldap.firstName = user.firstName;
				this.ldap.lastName = user.lastName;
				this.ldap.email = user.email;
				this.ldap.phoneNumber = user.phoneNumber;
				this.ldap.displayName = user.displayName;
				this.ldap.publicKey = user.publicKey ? user.publicKey : '';
			});

			return true;
		}

		this.result.error = 'An error occured while loading LDAP user or user does not exist, please try again.';

		return false;
	}

	async createLDAPUser() {
		this.clearResult();

		try {
			this.ldap.working = true;

			await this.api.createLDAPUser({
				userName: this.ldap.userName,
				firstName: this.ldap.firstName,
				lastName: this.ldap.lastName,
				displayName: this.ldap.displayName,
				email: this.ldap.email,
				password: this.ldap.password,
				phoneNumber: this.ldap.phoneNumber,
			});

			this.result.success = 'New LDAP user has been created successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `createLDAPUser` call: ' + e);

			this.result.error = 'An error occured while creating LDAP user, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
		}
	}

	async updateLDAPUser(username) {
		this.clearResult();

		try {
			this.ldap.working = true;

			await this.api.updateLDAPUser(username, {
				firstName: this.ldap.firstName,
				lastName: this.ldap.lastName,
				displayName: this.ldap.displayName,
				email: this.ldap.email,
				phoneNumber: this.ldap.phoneNumber,
				publicKey: this.ldap.publicKey ? this.ldap.publicKey : null
			});

			this.result.success = 'LDAP user details have been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `updateLDAPUser` call: ' + e);

			this.result.error = 'An error occured while updating LDAP user, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
		}
	}

	async changeLDAPUserPassword(username) {
		this.clearResult();

		try {
			this.ldap.working = true;

			await this.api.changeLDAPUserPassword(username, {
				password: this.ldap.password
			});

			this.ldap.password = '';

			this.result.success = 'LDAP user\'s password has been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `updateLDAPUserPassword` call: ' + e);

			this.result.error = 'An error occured while updating LDAP user\'s password, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
		}
	}

	async deleteLDAPUser(username) {
		this.clearResult();

		try {
			this.ldap.working = true;
			this.ldap.workingId = username;

			await this.api.deleteLDAPUser(username);
			
			this.result.success = 'LDAP user has been deleted successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `deleteLDAPUser` call: ' + e);

			this.result.error = 'An error occured while deleting LDAP user, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
			this.ldap.workingId = null;
		}
	}

	async loadMonitoredInstances(refreshing = false) {
		try {
			if (!refreshing) {
				this.monitor.loading = true;
			} else {
				this.monitor.refreshing = true;
			}

			const response = await this.api.loadMonitoredInstances();

			runInAction(() => {
				this.monitor.withIncidents = response.data.monitoredIssues;
				this.monitor.withoutIncidents = response.data.monitoredOk;
			});

			return true;
		} catch (e) {
			console.debug('Error during `loadMonitoredInstances` call: ' + e);

			this.result.error = 'An error occured while loading monitored instances, please try again.';
				
			return false;
		} finally {
			this.monitor.loading = false;
			this.monitor.refreshing = false;
		}
	}

	async loadMonitorThresholds() {
		try {
			this.monitor.loading = true;

			const response = await this.api.loadMonitorThresholds();

			this.monitor.thresholds = response.data;

			return true;
		} catch (e) {
			console.debug('Error during `loadMonitorThresholds` call: ' + e);

			this.result.error = 'An error occured while loading monitor thresholds, please try again.';
				
			return false;
		} finally {
			this.monitor.loading = false;
		}
	}

	async loadServers() {
		try {
			this.servers.loading = true;

			const response = await this.api.loadServers();

			this.servers.list = response.data;

			return true;
		} catch (e) {
			console.debug('Error during `loadServers` call: ' + e);

			this.result.error = 'An error occured while loading servers, please try again.';
				
			return false;
		} finally {
			this.servers.loading = false;
		}
	}

	async loadServer(id) {
		let server = this.servers.list.find(s => s.id === id);

		if (!server) {
			await this.loadServers();

			server = this.servers.list.find(s => s.id === id);
		}

		if (server) {
			runInAction(() => {
				this.servers.labels.country = server.labels && server.labels.country ? server.labels.country : '';
				this.servers.labels.region = server.labels && server.labels.region ? server.labels.region : '';
				this.servers.labels.comment = server.labels && server.labels.comment ? server.labels.comment : '';
			});

			return true;
		}

		this.result.error = 'An error occured while loading server or server does not exist, please try again.';

		return false;
	}

	async authorizeServer(id, value) {
		this.clearResult();

		try {
			this.servers.working = true;
			this.servers.workingId = id;

			await this.api.authorizeServer(id, value);

			this.result.success = 'Server authorization status has been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `authorizeServer` call: ' + e);

			this.result.error = 'An error occured while updating server authorization status, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
			this.servers.workingId = null;
		}
	}

	async changeServerDevelopmentFlag(id, value) {
		this.clearResult();

		try {
			this.servers.working = true;
			this.servers.workingId = id;

			await this.api.changeServerDevelopmentFlag(id, value);

			this.result.success = 'Server development status has been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `changeServerDevelopmentFlag` call: ' + e);

			this.result.error = 'An error occured while updating server development status, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
			this.servers.workingId = null;
		}
	}

	async labelServer(id) {
		this.clearResult();

		try {
			this.servers.working = true;

			await this.api.labelServer(id, this.servers.labels);

			this.result.success = 'Server lalbes have been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `labelServer` call: ' + e);

			this.result.error = 'An error occured while updating server labels, please try again.';
				
			return false;
		} finally {
			this.ldap.working = false;
		}
  }
  
  async loadServerIncidents(serverId) {
		try {
      this.incidents.loading = true;
      
			const response = await this.api.loadServerIncidents(serverId);

      this.incidents.list = response.data;

			return true;
		} catch (e) {
			console.debug('Error during `loadIncidents` call: ' + e);

			this.result.error = 'An error occured while loading tasks, please try again.';
				
			return false;
		} finally {
      this.incidents.loading = false;
		}
	}

	async loadTasks() {
		try {
			this.tasks.loading = true;

			const response = await this.api.loadTasks();

			this.tasks.list = response.data;

			return true;
		} catch (e) {
			console.debug('Error during `loadTasks` call: ' + e);

			this.result.error = 'An error occured while loading tasks, please try again.';
				
			return false;
		} finally {
			this.tasks.loading = false;
		}
	}

	async loadTask(id) {
		try {
			this.tasks.loading = true;

			const response = await this.api.loadTask(id);

			runInAction(() => {
				this.tasks.name = response.data.name ? response.data.name : '';
				this.tasks.mainScript = response.data.mainScript ? response.data.mainScript : '';
				this.tasks.cleanupScript = response.data.cleanupScript ? response.data.cleanupScript : '';
				this.tasks.rollbackScript = response.data.rollbackScript ? response.data.rollbackScript : '';
				this.tasks.monitored = response.data.monitored;
				this.tasks.frequency = response.data.frequency;
				this.tasks.runOnAll = response.data.runOnAll;
				this.tasks.runOnServer = response.data.runOnServer ? response.data.runOnServer : '';
			});

			return true;
		} catch (e) {
			console.debug('Error during `loadTask` call: ' + e);

			this.result.error = 'An error occured while loading task, please try again.';
				
			return false;
		} finally {
			this.tasks.loading = false;
		}
	}

	async createTask() {
		this.clearResult();

		try {
			this.tasks.working = true;

			await this.api.createTask({
				name: this.tasks.name,
				mainScript: this.tasks.mainScript,
				cleanupScript: this.tasks.cleanupScript,
				rollbackScript: this.tasks.rollbackScript,
				monitored: this.tasks.monitored,
				frequency: parseInt(this.tasks.frequency),
				runOnAll: this.tasks.runOnAll,
				runOnServer: this.tasks.runOnAll ? null : this.tasks.runOnServer
			});

			this.result.success = 'New task has been created successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `createTask` call: ' + e);

			this.result.error = 'An error occured while creating task, please try again.';
				
			return false;
		} finally {
			this.tasks.working = false;
		}
	}

	async updateTask(id) {
		this.clearResult();

		try {
			this.tasks.working = true;

			await this.api.updateTask(id, {
				name: this.tasks.name,
				mainScript: this.tasks.mainScript,
				cleanupScript: this.tasks.cleanupScript,
				rollbackScript: this.tasks.rollbackScript,
				monitored: this.tasks.monitored,
				frequency: parseInt(this.tasks.frequency),
				runOnAll: this.tasks.runOnAll,
				runOnServer: this.tasks.runOnAll ? null : this.tasks.runOnServer
			});

			this.result.success = 'Task details have been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `updateTask` call: ' + e);

			this.result.error = 'An error occured while updating task, please try again.';
				
			return false;
		} finally {
			this.tasks.working = false;
		}
	}

	async deleteTask(id) {
		this.clearResult();

		try {
			this.tasks.working = true;
			this.tasks.workingId = id;

			await this.api.deleteTask(id);
			
			this.result.success = 'Task has been deleted successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `deleteTask` call: ' + e);

			this.result.error = 'An error occured while deleting task, please try again.';
				
			return false;
		} finally {
			this.tasks.working = false;
			this.tasks.workingId = null;
		}
	}

	async executeTaskSync(serverId, taskId) {
		this.clearResult();

		try {
			this.tasks.working = true;

			const response = await this.api.executeTaskSync(serverId, taskId);
			
			runInAction(() => {
				this.result.suppressed = true;
				this.result.success = 'Task has been executed successfully.';
				this.result.details = response.data;
			});

			return true;
		} catch (e) {
			console.debug('Error during `executeTaskSync` call: ' + e);

			this.result.suppressed = true;
			this.result.error = 'An error occured while executing task, please try again.';
				
			return false;
		} finally {
			this.tasks.working = false;
		}
	}

	async executeTaskAsync(id) {
		this.clearResult();

		try {
			this.tasks.working = true;

			await this.api.executeTaskAsync(id, this.tasks.serversToRunTaskOn);
			
			this.result.success = 'Task execution has been queued successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `executeTaskAsync` call: ' + e);

			this.result.error = 'An error occured while executing task, please try again.';
				
			return false;
		} finally {
			this.tasks.working = false;
		}
	}

	async loadTasksStatus() {
		try {
			this.tasks.loadingStatus = true;

			const response = await this.api.loadTasksStatus();

			runInAction(() => {
				this.tasks.status.unfinished = response.data.unfinished;
				this.tasks.status.unviewed = response.data.unviewed;
			});

			return true;
		} catch (e) {
			console.debug('Error during `loadTasksStatus` call: ' + e);
				
			return false;
		} finally {
			this.tasks.loadingStatus = false;
		}
	}

	async loadTaskLogGroups() {
		try {
			this.tasks.loading = true;

			const response = await this.api.loadTaskLogGroups(this.settings.page);

			runInAction(() => {
				this.tasks.taskLogGroups.content = response.data.content;
				this.tasks.taskLogGroups.total = response.data.total;
			});

			return true;
		} catch (e) {
			console.debug('Error during `loadTaskLogGroups` call: ' + e);

			this.result.error = 'An error occured while loading task log groups, please try again.';
				
			return false;
		} finally {
			this.tasks.loading = false;
		}
	}

	async viewTaskLogGroup(id) {
		try {
			this.tasks.loading = true;

			const response = await this.api.viewTaskLogGroup(id);

			this.tasks.taskLogGroupData = response.data;			

			return true;
		} catch (e) {
			console.debug('Error during `viewTaskLogGroup` call: ' + e);

			this.result.error = 'An error occured while trying to view task log group, please try again.';
				
			return false;
		} finally {
			this.tasks.loading = false;
		}
	}

	async loadSettings() {
		try {
			this.settings.loading = true;

			const response = await this.api.loadSettings(this.settings.page);

			this.settings.list = response.data;

			return true;
		} catch (e) {
			console.debug('Error during `loadSettings` call: ' + e);

			this.result.error = 'An error occured while loading settings, please try again.';
				
			return false;
		} finally {
			this.settings.loading = false;
		}
	}

	async loadSetting(id) {
		try {
			this.settings.loading = true;

			const response = await this.api.loadSetting(id);

			runInAction(() => {
				this.settings.label = response.data.label;
				this.settings.key = response.data.key;
				this.settings.valueType = response.data.valueType;
				this.settings.value = response.data.valueType === 'BOOL' ? response.data.value === 'true' : response.data.value;
			});

			return true;
		} catch (e) {
			console.debug('Error during `loadSetting` call: ' + e);

			this.result.error = 'An error occured while loading settings item, please try again.';
				
			return false;
		} finally {
			this.settings.loading = false;
		}
	}

	async createSetting() {
		this.clearResult();

		try {
			this.settings.working = true;

			await this.api.createSetting({
				label: this.settings.label,
				key: this.settings.key,
				valueType: this.settings.valueType,
				value: this.settings.valueType === 'BOOL' ? (this.settings.value ? 'true' : 'false') : this.settings.value
			});

			this.result.success = 'New setting item has been created successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `createSetting` call: ' + e);

			this.result.error = 'An error occured while creating setting item, please try again.';
				
			return false;
		} finally {
			this.settings.working = false;
		}
	}

	async updateSetting(id) {
		this.clearResult();

		try {
			this.settings.working = true;
			this.settings.workingId = id;

			await this.api.updateSetting(id, {
				id: id,
				label: this.settings.label,
				key: this.settings.key,
				valueType: this.settings.valueType,
				value: this.settings.valueType === 'BOOL' ? (this.settings.value ? 'true' : 'false') : this.settings.value
			});

			this.result.success = 'Settings item has been updated successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `updateSetting` call: ' + e);

			this.result.error = 'An error occured while updating settings item, please try again.';
				
			return false;
		} finally {
			this.settings.working = false;
			this.settings.workingId = null;
		}
	}

	async deleteSetting(id) {
		this.clearResult();

		try {
			this.settings.working = true;
			this.settings.workingId = id;

			await this.api.deleteSetting(id);
			
			this.result.success = 'Settings item has been deleted successfully.';

			return true;
		} catch (e) {
			console.debug('Error during `deleteSetting` call: ' + e);

			this.result.error = 'An error occured while deleting settings item, please try again.';
				
			return false;
		} finally {
			this.settings.working = false;
			this.settings.workingId = null;
		}
	}

	// ---

	get isAppReady() {
		for (const state in this.readyState) {
			if (!this.readyState[state]) return false;
		}

		return true;
	}

	get isAuthenticated() {
		return this.user.authenticated;
	}

	get isAdmin() {
		if (this.user.self && this.user.self.authorities) {
			return !!this.user.self.authorities.find(a => a.authority === 'ROLE_ADMIN');
		}

		return false;
	}

	get withIncidentsCount() {
		return this.monitor.withIncidents.length;
	}

	get withoutIncidentsCount() {
		return this.monitor.withoutIncidents.length;
	}

	get filteredMonitoredInstances() {
		const { query, withIncidents, withoutIncidents } = this.monitor;

		if (!query) {
			return {
				withIncidents: withIncidents,
				withoutIncidents: withoutIncidents
			};
		}

		const lcQuery = query.toLowerCase();

		const filterInstance = instance => {
			let include = false;

			if (instance.server.hostname.toLowerCase().includes(lcQuery)) {
				include = true;
			}
			 
			if (instance.server.ipAddress.includes(lcQuery)) {
				include = true;
			}

			if (instance.server.labels && instance.server.labels.filter(label => label.toLowerCase().includes(lcQuery)).length > 0) {
				include = true;
			}

			return include;
		};

		const withIncidentsFiltered = withIncidents.filter(filterInstance);
		const withoutIncidentsFiltered = withoutIncidents.filter(filterInstance);

		return {
			withIncidents: withIncidentsFiltered,
			withoutIncidents: withoutIncidentsFiltered
		};
	}

}

decorate(uTrackStore, {
	readyState: observable,
	result: observable,
	user: observable,
	ldap: observable,
	monitor: observable,
  servers: observable,
  incidents: observable,
  tasks: observable,
	settings: observable,
	isAppReady: computed,
	isAuthenticated: computed,
	isAdmin: computed,
	withIncidentsCount: computed,
	withoutIncidentsCount: computed,
	filteredMonitoredInstances: computed
});

export default new uTrackStore();
