const Config = {

	API_URL: 'https://utrack.mkint.net:8443',

	PAGING_PER_PAGE: 20,
	PAGING_MAX_RESULTS: 100

};

Object.freeze(Config);

export default Config;
