import React, { Component } from 'react';
import { observer } from 'mobx-react';

const Footer = observer(
	class Footer extends Component {

		render() {
			return (
				<footer className="footer">
					<p className="footer__copy">&copy; {new Date().getFullYear()} uTrack</p>
				</footer>
			)
		}

	}
);

export default Footer;
