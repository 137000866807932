import React, { Component, Fragment } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import ResultMessage from '../base/ResultMessage';
import Loader from '../base/Loader';

import { IoMdArrowBack } from 'react-icons/io';

const LDAPUserForm = observer(
	class LDAPUserForm extends Component {

		constructor(props) {
			super(props);

			this.userNameRef = React.createRef();
			this.firstNameRef = React.createRef();
		}

		componentDidMount() {
			uTrackStore.clearResult();

			runInAction(() => {
				uTrackStore.ldap.userName = '';
				uTrackStore.ldap.password = '';
				uTrackStore.ldap.firstName = '';
				uTrackStore.ldap.lastName = '';
				uTrackStore.ldap.email = '';
				uTrackStore.ldap.phoneNumber = '';
				uTrackStore.ldap.displayName = '';
				uTrackStore.ldap.publicKey = '';

				uTrackStore.ldap.preview = false;
			});

			const { username } = this.props.match.params;

			if (username) {
				uTrackStore.loadLDAPUser(username).then(result => {
					if (!result) {
						uTrackStore.preserveResult();
	
						this.props.history.push('/ldap/users');
					}
				});
			}

			if (this.userNameRef.current) {
				this.userNameRef.current.focus();
			}
		}

		handleUserNameChange = e => {
			uTrackStore.ldap.userName = e.target.value;
		}

		handlePasswordChange = e => {
			uTrackStore.ldap.password = e.target.value;
		}

		handleFirstNameChange = e => {
			uTrackStore.ldap.firstName = e.target.value;
		}

		handleLastNameChange = e => {
			uTrackStore.ldap.lastName = e.target.value;
		}

		handleEmailChange = e => {
			uTrackStore.ldap.email = e.target.value;
		}

		handlePhoneNumberChange = e => {
			uTrackStore.ldap.phoneNumber = e.target.value;
		}

		handleDisplayNameChange = e => {
			uTrackStore.ldap.displayName = e.target.value;
		}

		handlePublicKeyChange = e => {
			uTrackStore.ldap.publicKey = e.target.value;
		}

		handlePreviewClick = e => {
			e.preventDefault();

			uTrackStore.ldap.preview = true;
		}

		handlePreviewCancelClick = e => {
			e.preventDefault();

			uTrackStore.ldap.preview = false;
		}

		handleSubmitClick = async e => {
			e.preventDefault();

			const { username } = this.props.match.params;
			
			if (username) {
				await uTrackStore.updateLDAPUser(username);
			} else {
				await uTrackStore.createLDAPUser();
			}

			const { result: { success } } = uTrackStore;

			if (success) {
				uTrackStore.preserveResult();

				this.props.history.push('/ldap/users');
			}
		}

		render() {
			const { username } = this.props.match.params;
			const { ldap: { userName, password, firstName, lastName, email, phoneNumber, displayName, publicKey, preview, loading, working } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">
							{username ? 'Update LDAP user' : 'Create LDAP user'}
						</h2>
					</div>

					<div className="section__content">
						<p className="section__text">
							{username ? 'To update LDAP user please fill out the form below.' : 'To create new LDAP user please fill out the form below.'}</p>

						<ResultMessage />

						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading LDAP user, please wait ...
							</p>
						) : (
							<form className="form">
								{!preview ? (
									<Fragment>
										{!username ? (
											<div className="form__row">
												<div className="form__column form__column--short">
													<div className="form__group">
														<label htmlFor="userName" className="form__label">Username</label>
														<input ref={this.userNameRef} type="text" id="userName" className="form__input" placeholder="Username ..." onChange={this.handleUserNameChange} value={userName} />
													</div>
												</div>

												<div className="form__column form__column--short">
													<div className="form__group">
														<label htmlFor="password" className="form__label">Password</label>
														<input type="password" id="password" className="form__input" placeholder="Password ..." onChange={this.handlePasswordChange} value={password} />
													</div>
												</div>
											</div>
										) : null}

										<div className="form__row">
											<div className="form__column form__column--short">
												<div className="form__group">
													<label htmlFor="firstName" className="form__label">First name</label>
													<input ref={this.firstNameRef} type="text" id="firstName" className="form__input" placeholder="First name ..." onChange={this.handleFirstNameChange} value={firstName} />
												</div>
											</div>

											<div className="form__column form__column--short">
												<div className="form__group">
													<label htmlFor="lastName" className="form__label">Last name</label>
													<input type="text" id="lastName" className="form__input" placeholder="Last name ..." onChange={this.handleLastNameChange} value={lastName} />
												</div>
											</div>
										</div>

										<div className="form__row">
											<div className="form__column form__column--short">
												<div className="form__group">
													<label htmlFor="email" className="form__label">E-mail</label>
													<input type="email" id="email" className="form__input" placeholder="E-mail ..." onChange={this.handleEmailChange} value={email} />
												</div>
											</div>

											<div className="form__column form__column--short">
												<div className="form__group">
													<label htmlFor="phoneNumber" className="form__label">Phone number</label>
													<input type="text" id="phoneNumber" className="form__input" placeholder="Phone number ..." onChange={this.handlePhoneNumberChange} value={phoneNumber} />
												</div>
											</div>
										</div>

										<div className="form__group">
											<label htmlFor="displayName" className="form__label">Display name</label>
											<input type="text" id="displayName" className="form__input form__input--short" placeholder="Display name ..." onChange={this.handleDisplayNameChange} value={displayName} />
										</div>

										{username ? (
											<div className="form__group">
												<label htmlFor="publicKey" className="form__label">Public key (optional)</label>
												<textarea rows="7" id="publicKey" className="form__input form__input--wide form__input--monospace" placeholder="Paste your public key ..." onChange={this.handlePublicKeyChange} value={publicKey} />
											</div>
										) : null}
									</Fragment>
								) : (
									<Fragment>
										<div className="section__row">
											<div className="section__column">
												<h3 className="section__subtitle">Details</h3>

												<div className="account-details">
													<dl className="account-details__list">
														<dt className="account-details__list-title">Name</dt>
														<dd className="account-details__list-content">{firstName} {lastName}</dd>

														<dt className="account-details__list-title">E-mail</dt>
														<dd className="account-details__list-content">{email}</dd>

														<dt className="account-details__list-title">Phone number</dt>
														<dd className="account-details__list-content">{phoneNumber}</dd>

														<dt className="account-details__list-title">Display name</dt>
														<dd className="account-details__list-content">{displayName}</dd>
													</dl>
												</div>
											</div>

											<div className="section__column">
												<h3 className="section__subtitle">Public key</h3>

												{publicKey ? (
													<pre className="account-details__public-key">{publicKey}</pre>
												) : (
													<p className="section__text">Public key is missing.</p>
												)}
											</div>
										</div>
									</Fragment>
								)}

								<div className="form__buttons">
									{preview ? (
										<button className="button button--secondary button--icon button--icon-left" onClick={this.handlePreviewCancelClick}>
											<IoMdArrowBack className="button__icon" /> Cancel
										</button>
									) : null}

									<button disabled={working || (!username && !userName) || (!username && !password) || !firstName || !lastName || !email || !phoneNumber || !displayName} className="button button--primary button--loader" onClick={!username || preview ? this.handleSubmitClick : this.handlePreviewClick}>
										{working ? (
											<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
										) : null}
										{username ? (preview ? 'Save changes' : 'Preview changes') : 'Create LDAP user'}
									</button>
								</div>
							</form>
						)}
					</div>
				</section>
			);
		}

	}
);

export default LDAPUserForm;
