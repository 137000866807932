import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

import uTrackLogo from '../../assets/img/uTrack-logo.png';

const Login = observer(
	class Login extends Component {

		constructor(props) {
			super(props);

			this.usernameRef = React.createRef();
		}

		componentDidMount() {
			const { isAuthenticated } = uTrackStore;

			if (isAuthenticated) {
				this.props.history.replace('/');
			}

			if (this.usernameRef.current) {
				this.usernameRef.current.focus();
			}
		}

		handleUsernameChange = e => {
			uTrackStore.user.username = e.target.value;
		}

		handlePasswordChange = e => {
			uTrackStore.user.password = e.target.value;
		}

		handleSignInClick = async e => {
			e.preventDefault();

			if (await uTrackStore.login()) {
				this.props.history.push('/');
			}
		}

		render() {
			const { username, password, working } = uTrackStore.user;

			return (
				<div className="login">
					<div className="login__content">
						<img className="login__logo" src={uTrackLogo} alt="uTrack" title="uTrack" />

						<ResultMessage center={true} />

						<form className="form">
							<div className="form__group">
								<input ref={this.usernameRef} className="form__input" type="text" placeholder="Username ..." value={username} onChange={this.handleUsernameChange} autoComplete="username" />
							</div>

							<div className="form__group">
								<input className="form__input" type="password" placeholder="Password ..." value={password} onChange={this.handlePasswordChange} autoComplete="current-password" />
							</div>

							<div className="form__buttons form__buttons--center">
								<button disabled={working|| !username || !password} className="button button--primary button--loader" onClick={this.handleSignInClick}>
									{working ? (
										<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
									) : null}
									Sign in
								</button>
							</div>
						</form>
					</div>
				</div>
			);
		}

	}
);

export default Login;
