import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';
import { without, union } from 'lodash';
import MonitoredInstance from '../base/MonitoredInstance';

import { IoMdSearch, IoMdList, IoMdAlert } from 'react-icons/io';

const Dashboard = observer(
	class Dashboard extends Component {

		constructor(props) {
			super(props);

			this.state = {
				expandedInstances: []
			};

			this.refreshInterval = null;
		}

		componentDidMount() {
			uTrackStore.loadMonitorThresholds();

			this.loadData();

			this.refreshInterval = setInterval(() => {
				const { monitor: { refreshing } } = uTrackStore;

				if (!refreshing) {
					this.loadData(true);
				}
			}, 10000);
		}

		componentWillUnmount() {
			if (this.refreshInterval) {
				clearInterval(this.refreshInterval);
			}
		}

		loadData = (refreshing = false) => {
			uTrackStore.loadMonitoredInstances(refreshing).then(() => {
				const { filteredMonitoredInstances: { withIncidents } } = uTrackStore;

				this.setState((state) => {
					return {
						expandedInstances: union(state.expandedInstances, withIncidents.reduce((a, c) => {
							a.push(c.server.id);

							return a;
						}, []))
					}
				});
			});
		}

		handleQueryChange = e => {
			uTrackStore.monitor.query = e.target.value;
		}

		handleInstanceClick = id => {
			this.setState((state) => {
				return {
					expandedInstances: state.expandedInstances.includes(id) ? without(state.expandedInstances, id) : union(state.expandedInstances, [id])
				}
			});
		}

		render() {
			const { monitor: { query, loading, refreshing }, withIncidentsCount, withoutIncidentsCount, filteredMonitoredInstances: { withIncidents, withoutIncidents } } = uTrackStore;
			const { expandedInstances } = this.state;

			return (
				<section className="section dashboard">
					<div className="section__header">
						<h2 className="section__title">Dashboard</h2>

						<div className="section__controls">
							{refreshing ? (
								<div className="section__control section__control--loader">
									<Loader className="section__control-loader" size="tiny" />
								</div>
							) : null}

							<div className="section__control section__control--icon section__control--icon-left">
								<input type="text" id="query" className="section__input" placeholder="Filter by query ..." onChange={this.handleQueryChange} value={query} />
								<IoMdSearch className="section__control-icon" />
							</div>
						</div>
					</div>

					<ResultMessage />

					{loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading account details, please wait ...
							</p>
						</div>
					) : (
						<Fragment>
							{withIncidentsCount === 0 && withoutIncidentsCount === 0 ? (
								<div className="section__content">
									<p className="section__text">There are no instances to show.</p>
								</div>
							) : null}

							{withIncidentsCount > 0 ? (
								<div className="section__content section__content--no-padding">
									<h3 className="section__subtitle">
										<IoMdAlert className="section__subtitle-icon" />
										Active incidents
									</h3>

									{withIncidents.length === 0 ? (
										<p className="section__text section__text--bottom-padding">Some results ({withIncidentsCount - withIncidents.length}) are hidden by your current search filters.</p>
									) : (
										<div className="instances instances--issues">
											{withIncidents.map(instance => (
												<MonitoredInstance key={instance.server.id} instance={instance} withIssue={true} expanded={expandedInstances.includes(instance.server.id)} onHandleInstanceClick={this.handleInstanceClick} />
											))}
										</div>
									)}
								</div>
							) : null}

							{withoutIncidentsCount > 0 ? (
								<div className="section__content section__content--no-padding">
									<h3 className="section__subtitle">
										<IoMdList className="section__subtitle-icon" />
										{withIncidentsCount > 0 ? 'Other servers' : 'Servers'}
									</h3>

									{withoutIncidents.length === 0 ? (
										<p className="section__text section__text--bottom-padding">Some results ({withoutIncidentsCount - withoutIncidents.length}) are hidden by your current search filters.</p>
									) : (
										<div className="instances">
											{withoutIncidents.map(instance => (
												<MonitoredInstance key={instance.server.id} instance={instance} expanded={expandedInstances.includes(instance.server.id)} onHandleInstanceClick={this.handleInstanceClick} />
											))}
										</div>
									)}
								</div>
							) : null}
						</Fragment>
					)}
				</section>
			);
		}

	}
);

export default Dashboard;
