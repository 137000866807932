import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Loader from '../base/Loader';

const Loading = observer(
	class Loading extends Component {

		render() {
			return (
				<div className="loading">
					<div className="loading__content">
						<Loader className="loading__loader" />

						<p className="loading__text">uTrack Dashboard is initializing, please wait ...</p>
					</div>
				</div>
			);
		}

	}
);

export default Loading;
