import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../dashboard/Dashboard';
import Account from '../account/Account';
import AccountUpdate from '../account/AccountUpdate';
import Passwowrd from '../account/Password';
import LDAPUsers from '../ldap/LDAPUsers';
import LDAPUserView from '../ldap/LDAPUserView';
import LDAPUserForm from '../ldap/LDAPUserForm';
import LDAPUserPassword from '../ldap/LDAPUserPassword';
import ServerList from '../servers/ServerList';
import ServerLabels from '../servers/ServerLabels';
import TaskList from '../tasks/TaskList';
import TaskForm from '../tasks/TaskForm';
import TaskExecute from '../tasks/TaskExecute';
import TaskLog from '../tasks/TaskLog';
import TaskLogView from '../tasks/TaskLogView';
import Settings from '../settings/Settings';
import SettingsForm from '../settings/SettingsForm';
import Login from '../login/Login';
import Logout from '../logout/Logout';
import HTTP404 from '../error/HTTP404';
import uTrackStore from '../../stores/uTrackStore';

const Main = withRouter(observer(
	class Main extends Component {

		componentDidUpdate(prevProps) {
			if (this.props.location.pathname !== prevProps.location.pathname) {
				uTrackStore.clearResult();
			}
		}

		render() {
			return (
				<Switch>
					<Route exact path="/user/login" component={Login} />
					<Route exact path="/user/logout" component={Logout} />
					
					<ProtectedRoute exact path="/" component={Dashboard} />

					<ProtectedRoute exact path="/user/account" component={Account} />
					<ProtectedRoute exact path="/user/account/update" component={AccountUpdate} />
					<ProtectedRoute exact path="/user/password" component={Passwowrd} />

					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/ldap/users" component={LDAPUsers} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/ldap/users/create" component={LDAPUserForm} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/ldap/users/view/:username" component={LDAPUserView} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/ldap/users/update/:username" component={LDAPUserForm} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/ldap/users/password/:username" component={LDAPUserPassword} />

					<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_USER']} exact path="/servers" component={ServerList} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/servers/labels/:id" component={ServerLabels} />

					<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_USER']} exact path="/tasks" component={TaskList} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/tasks/create" component={TaskForm} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/tasks/edit/:id" component={TaskForm} />
					<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_USER']} exact path="/tasks/execute/:id" component={TaskExecute} />
					<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_USER']} exact path="/tasks/log" component={TaskLog} />
					<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_USER']} exact path="/tasks/log/view/:id" component={TaskLogView} />

					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/settings" component={Settings} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/settings/create" component={SettingsForm} />
					<ProtectedRoute roles={['ROLE_ADMIN']} exact path="/settings/edit/:id" component={SettingsForm} />

					<Route component={HTTP404} />
				</Switch>
			);
		}

	}
));

export default Main;
