import React, { PureComponent } from 'react';

class ProgressBar extends PureComponent {

	colorClass = baseClass => {
		const { max, value, indicatorType, indicatorValues } = this.props;

		if (indicatorType === 'percent') {
			const percent = (100 * value) / max;

			if (percent < indicatorValues.green) {
				return baseClass + '--green';
			}

			if (percent >= indicatorValues.green && percent < indicatorValues.orange) {
				return baseClass + '--orange';
			}

			if (percent >= indicatorValues.red) {
				return baseClass + '--red';
			}
		}
	}

	render() {
		const { className, label, min, max, value, unit, description } = this.props;
		const percent = ((100 * value) / max).toFixed(0);


		return (
			<div className={`progress-bar ${className}`}>
				<div className="progress-bar__header">
					<div className="progress-bar__label" title={description}>{label}{unit ? ` (${unit})` : ''}</div>
					<div className={`progress-bar__value ${this.colorClass('progress-bar__value')}`}>{value} ({percent}%)</div>
				</div>

				<div className="progress-bar__rail">
					<div className={`progress-bar__bar ${this.colorClass('progress-bar__bar')}`} style={{ width: percent + '%' }}></div>
				</div>

				<div className="progress-bar__ticks">
					<div className="progress-bar__tick-min">{min}</div>
					<div className="progress-bar__tick-min">{max}</div>
				</div>
			</div>
		);
	}

}

ProgressBar.defaultProps = {
	min: 0
};

export default ProgressBar;
