import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import { NavLink } from 'react-router-dom';

import { IoMdClipboard } from 'react-icons/io';

const MenuItemTasks = observer(
	class MenuItemTasks extends Component {

		constructor(props) {
			super(props);

			this.refreshInterval = null;
		}

		componentDidMount() {
			uTrackStore.loadTasksStatus();

			this.refreshInterval = setInterval(() => {
				const { tasks: { loadingStatus } } = uTrackStore;

				if (!loadingStatus) {
					uTrackStore.loadTasksStatus();
				}
			}, 10000);
		}

		componentWillUnmount() {
			if (this.refreshInterval) {
				clearInterval(this.refreshInterval);
			}
		}

		render() {
			const { tasks: { status: { unfinished, unviewed } } } = uTrackStore;

			return (
				<li className="menu__item">
					<NavLink to="/tasks/log" className="menu__link" activeClassName="menu__link--active">
						<IoMdClipboard className="menu__icon" /> Tasks ({unfinished} running / {unviewed} unread)
					</NavLink>
				</li>
			);
		}

	}
);

export default MenuItemTasks;
