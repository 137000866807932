import React, { PureComponent, Fragment } from 'react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from './Loader';

import { IoMdCreate, IoMdCheckmark, IoMdClose } from 'react-icons/io';

class InlineSettingItem extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			editing: false,
			error: false,
			errorMessage: '',

			value: false
		};
	}

	handleEditClick = e => {
		const { type, value } = this.props;

		uTrackStore.clearResult();

		this.setState({
			editing: true,
			value: type === 'BOOL' ? value === 'true' : value
		});
	}

	handleSaveClick = async () => {
		const { id, onHandleSaveClick } = this.props;
		const { value } = this.state;

		this.setState({
			error: false,
			errorMessage: ''
		});

		try {
			await onHandleSaveClick(id, value);

			this.setState({
				editing: false
			});
		} catch (e) {
			const error = e.errors.find(er => er.field === 'value');

			this.setState({
				error: true,
				errorMessage: error ? error.message : ''
			});
		}		
	}

	handleCancelClick = () => {
		this.setState({
			editing: false
		});
	}

	handleInputChange = e => {
		const value = e.target.value;
		const { type } = this.props;

		this.setState({
			value: type === 'BOOL' ? value === 'true' : value 
		});
	}

	render() {
		const { id, type, value: initialValue, password } = this.props;
		const { editing, value, error, errorMessage } = this.state;
		const { settings: { workingId } } = uTrackStore;

		return (
			<div className="inline-setting">
				{editing ? (
					<Fragment>
						<div className="inline-setting__wrap">
							{type === 'BOOL' ? (
								<Fragment>
									<label className="form__radio-wrap form__radio-wrap--inline">
										<input type="radio" className="form__radio" checked={value} value={true} onChange={this.handleInputChange} />
										<span className="form__radio-text">Yes</span>
									</label>
									<label className="form__radio-wrap form__radio-wrap--inline">
										<input type="radio" className="form__radio" checked={!value} value={false} onChange={this.handleInputChange} />
										<span className="form__radio-text">No</span>
									</label>
								</Fragment>
							) : null}

							{type === 'STRING' ? (
								<input type={password ? 'password' : 'text'} className="inline-setting__input" placeholder="Value ..." onChange={this.handleInputChange} value={value} />
							) : null}

							{type === 'TEXT' ? (
								<textarea rows="3" className="inline-setting__input" placeholder="Value ..." onChange={this.handleInputChange} value={value} />
							) : null}

							{type === 'INTEGER' || type === 'DECIMAL' ? (
								<input type="number" className="inline-setting__input" placeholder="Value ..." onChange={this.handleInputChange} value={value} />
							) : null}
						</div>

						{error ? (
							<p className="inline-setting__error">{errorMessage}</p>
						) : null}

						<div className="inline-setting__buttons">
							<button className="button button--small button--success" onClick={this.handleSaveClick}>
								{workingId === id ? (
									<Loader className="button__loader" size="micro" foreground="#fff" background="rgba(255,255,255,.15)" />
								) : (
									<IoMdCheckmark className="inline-setting__icon" />
								)}
								Save
							</button>

							<button className="button button--small button--danger" onClick={this.handleCancelClick}>
								<IoMdClose className="inline-setting__icon" />
								Cancel
							</button>
						</div>
					</Fragment>
				) : (
					<button className="inline-setting__button" onClick={this.handleEditClick}>
						<IoMdCreate className="inline-setting__icon" />
						<span className="inline-setting__text">
							{password ? initialValue.split('').map(char => '*') : (type === 'BOOL' ? (initialValue === 'true' ? 'Yes' : 'No') : initialValue)}
							{!initialValue ? (
								<span className="inline-setting__empty">(empty)</span>
							) : null}
						</span>
					</button>
				)}
			</div>
		);
	}

}

export default InlineSettingItem;
