import React, { Component } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import ResultMessage from '../base/ResultMessage';
import Loader from '../base/Loader';

const ServerLabels = observer(
	class ServerLabels extends Component {

		constructor(props) {
			super(props);

			this.countryRef = React.createRef();
		}

		componentDidMount() {
			uTrackStore.clearResult();

			runInAction(() => {
				uTrackStore.servers.labels.country = '';
				uTrackStore.servers.labels.region = '';
				uTrackStore.servers.labels.comment = '';
			});

			const { id } = this.props.match.params;

			uTrackStore.loadServer(id).then(result => {
				if (!result) {
					uTrackStore.preserveResult();

					this.props.history.push('/servers');
				}
			});

			if (this.countryRef.current) {
				this.countryRef.current.focus();
			}
		}

		handleCountryChange = e => {
			uTrackStore.servers.labels.country = e.target.value;
		}

		handleRegionChange = e => {
			uTrackStore.servers.labels.region = e.target.value;
		}

		handleCommentChange = e => {
			uTrackStore.servers.labels.comment = e.target.value;
		}

		handleSaveLabelsClick = async e => {
			e.preventDefault();

			const { id } = this.props.match.params;
			
			await uTrackStore.labelServer(id);

			const { result: { success } } = uTrackStore;

			if (success) {
				uTrackStore.preserveResult();

				this.props.history.push('/servers');
			}
		}

		render() {
			const { servers: { labels: { country, region, comment }, loading, working } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Server labels</h2>
					</div>

					<div className="section__content">
						<p className="section__text">To update server labels please fill out the form below.</p>

						<ResultMessage />

						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading server, please wait ...
							</p>
						) : (
							<form className="form">
								<div className="form__group">
									<label htmlFor="country" className="form__label">Country</label>
									<input ref={this.countryRef} type="text" id="country" className="form__input form__input--short" placeholder="Country label ..." onChange={this.handleCountryChange} value={country} />
								</div>

								<div className="form__group">
									<label htmlFor="region" className="form__label">Region</label>
									<input type="text" id="region" className="form__input form__input--short" placeholder="Region label ..." onChange={this.handleRegionChange} value={region} />
								</div>

								<div className="form__group">
									<label htmlFor="comment" className="form__label">Comment</label>
									<input type="text" id="comment" className="form__input form__input--short" placeholder="Comment label ..." onChange={this.handleCommentChange} value={comment} />
								</div>

								<div className="form__buttons">
									<button disabled={working} className="button button--primary button--loader" onClick={this.handleSaveLabelsClick}>
										{working ? (
											<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
										) : null}
										Save changes
									</button>
								</div>
							</form>
						)}
					</div>
				</section>
			);
		}

	}
);

export default ServerLabels;
