import React, { Component, Fragment } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import ResultMessage from '../base/ResultMessage';
import Loader from '../base/Loader';
import SettingTypes from '../../types/SettingTypes';

const SettingsForm = observer(
	class SettingsForm extends Component {

		constructor(props) {
			super(props);

			this.labelRef = React.createRef();
		}

		componentDidMount() {
			uTrackStore.clearResult();

			runInAction(() => {
				uTrackStore.settings.label = '';
				uTrackStore.settings.key = '';
				uTrackStore.settings.valueType = '';
				uTrackStore.settings.value = false;
			});

			const { id } = this.props.match.params;

			if (id) {
				uTrackStore.loadSetting(id).then(result => {
					if (!result) {
						uTrackStore.preserveResult();
	
						this.props.history.push('/settings');
					}
				});
			}

			if (this.labelRef.current) {
				this.labelRef.current.focus();
			}
		}

		handleLabelChange = e => {
			uTrackStore.settings.label = e.target.value;
		}

		handleKeyChange = e => {
			uTrackStore.settings.key = e.target.value;
		}

		handleValueTypeChange = e => {
			const newValue = e.target.value;
			const { settings: { valueType, value } } = uTrackStore;
			let convertedValue = value;
			
			if (newValue === 'BOOL') {
				convertedValue = false;
			}

			if (newValue === 'STRING' || newValue === 'TEXT') {
				if (valueType === 'BOOL' || valueType === '') {
					convertedValue = '';
				}
			}

			if (newValue === 'INTEGER') {
				convertedValue = parseInt(value);

				if (isNaN(convertedValue)) {
					convertedValue = 0;
				}
			}

			if (newValue === 'DECIMAL') {
				convertedValue = parseFloat(value);

				if (isNaN(convertedValue)) {
					convertedValue = 0.0;
				}
			}

			runInAction(() => {
				uTrackStore.settings.valueType = newValue;
				uTrackStore.settings.value = convertedValue;
			});
		}

		handleValueChange = e => {
			const { settings: { valueType } } = uTrackStore;

			if (valueType === 'BOOL') {
				uTrackStore.settings.value = e.target.value === 'true';
			} else {
				uTrackStore.settings.value = e.target.value;
			}
		}

		handleSubmitClick = async e => {
			e.preventDefault();

			const { id } = this.props.match.params;
			
			if (id) {
				await uTrackStore.updateSetting(id);
			} else {
				await uTrackStore.createSetting();
			}

			const { result: { success } } = uTrackStore;

			if (success) {
				uTrackStore.preserveResult();

				this.props.history.push('/settings');
			}
		}

		render() {
			const { id } = this.props.match.params;
			const { settings: { label, key, valueType, value, loading, working } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">
							{id ? 'Edit settings item' : 'Create settings item'}
						</h2>
					</div>

					<div className="section__content">
						<p className="section__text">
							{id ? 'To edit settings item please fill out the form below.' : 'To create new settings item please fill out the form below.'}</p>

						<ResultMessage />

						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading settings item, please wait ...
							</p>
						) : (
							<form className="form">
								<div className="form__group">
									<label htmlFor="label" className="form__label">Label</label>
									<input ref={this.labelRef} type="text" id="label" className="form__input form__input--short" placeholder="Label ..." onChange={this.handleLabelChange} value={label} />
								</div>

								<div className="form__group">
									<label htmlFor="key" className="form__label">Key</label>
									<input type="text" id="key" className="form__input form__input--short" placeholder="Key ..." onChange={this.handleKeyChange} value={key} />
								</div>

								<div className="form__group">
									<label htmlFor="valueType" className="form__label">Type</label>
									<select id="runOnServer" className="form__select form__input--short" value={valueType} onChange={this.handleValueTypeChange}>
										<option>Select type ...</option>
										{SettingTypes.map(t => (
											<option key={t.type} value={t.type}>{t.name}</option>
										))}
									</select>
								</div>

								{valueType ? (
									<div className="form__group">
										<label className="form__label">Value</label>
										{valueType === 'BOOL' ? (
											<Fragment>
												<label className="form__radio-wrap">
													<input type="radio" className="form__radio" checked={value} value={true} onChange={this.handleValueChange} />
													<span className="form__radio-text">Yes</span>
												</label>
												<label className="form__radio-wrap">
													<input type="radio" className="form__radio" checked={!value} value={false} onChange={this.handleValueChange} />
													<span className="form__radio-text">No</span>
												</label>
											</Fragment>
										) : null}
										{valueType === 'STRING' ? (
											<input type={key.toLowerCase().indexOf('password') === -1 ? 'text' : 'password'} id="value" className="form__input" placeholder="Value ..." onChange={this.handleValueChange} value={value} />
										) : null}
										{valueType === 'TEXT' ? (
											<textarea rows="5" id="value" className="form__input" placeholder="Value ..." onChange={this.handleValueChange} value={value} />
										) : null}
										{valueType === 'INTEGER' || valueType === 'DECIMAL' ? (
											<input type="number" id="value" className="form__input form__input--short" placeholder="Value ..." onChange={this.handleValueChange} value={value} />
										) : null}
									</div>
								) : null}

								<div className="form__buttons">
									<button disabled={working || !label || !key || !valueType} className="button button--primary button--loader" onClick={this.handleSubmitClick}>
										{working ? (
											<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
										) : null}
										{id ? 'Save changes' : 'Create settings item'}
									</button>
								</div>
							</form>
						)}
					</div>
				</section>
			);
		}

	}
);

export default SettingsForm;
