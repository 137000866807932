const SettingTypes = [{
	type: 'BOOL', name: 'Boolean'
}, {
	type: 'STRING', name: 'String'
} , {
	type: 'TEXT', name: 'Text'
}, {
	type: 'INTEGER', name: 'Integer'
}, {
	type: 'DECIMAL', name: 'Decimal'
}];

Object.freeze(SettingTypes);

export default SettingTypes;
