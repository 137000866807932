import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import { Link } from 'react-router-dom';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

import { IoMdThumbsUp, IoMdThumbsDown, IoMdBug, IoMdPricetags } from 'react-icons/io';

const ServerList = observer(
	class ServerList extends Component {

		componentDidMount() {
			uTrackStore.loadServers();
		}

		handleAuthorizeClick = async id => {
			const { servers: { list } } = uTrackStore;
			const value = list.find(s => s.id === id).authorized ? 0 : 1;

			await uTrackStore.authorizeServer(id, value);

			uTrackStore.loadServers();
		}

		handleDeveloperClick = async id => {
			const { servers: { list } } = uTrackStore;
			const value = list.find(s => s.id === id).development ? 0 : 1;

			await uTrackStore.changeServerDevelopmentFlag(id, value);

			uTrackStore.loadServers();
		}

		render() {
			const { isAdmin, servers: { list, loading, workingId } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Servers</h2>
					</div>

					<ResultMessage />

					{loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading servers, please wait ...
							</p>
						</div>
					) : (
						list.length === 0 ? (
							<div className="section__content">
								<p className="section__text">There are no servers to show.</p>
							</div>
						) : (
							<div className="section__table">
								<table className="table">
									<thead className="table__head">
										<tr>
											<th>Hostname</th>
											<th>IP address</th>
											<th>Auth / Dev</th>
											<th>Serial</th>
											{isAdmin ? (
												<th className="table__cell--least-space">Action</th>
											) : null}
										</tr>
									</thead>

									<tbody>
										{list.map(s => (
											<tr key={s.id}>
												<td>
													<span className="table__lead">{s.hostname ? s.hostname : '(empty)'}</span>
													<br />
													<span className="table__muted">
														{s.details.cpuName}
														<br />
														{s.details.cpuNumber} &times; CPU / {s.details.diskNumber} &times; HDD / RAM &times; {(s.details.totalMemory / 1024 / 1024).toFixed(0)} MB
													</span>
													{s.labels && (s.labels.country || s.labels.region || s.labels.comment)  ? (
														<Fragment>
															<br />
															<div className="table__tags">
																{s.labels.country ? <span className="table__tag">#{s.labels.country}</span> : null}
																{s.labels.region ? <span className="table__tag">#{s.labels.region}</span> : null}
																{s.labels.comment ? <span className="table__tag">#{s.labels.comment}</span> : null}
															</div>
														</Fragment>
													) : null}
												</td>
												<td>{s.ipAddress}</td>
												<td>{s.authorized ? 'Yes' : 'No'} / {s.development ? 'Yes' : 'No'}</td>
												<td>{s.serialNumber}</td>
												{isAdmin ? (
													<td className="table__cell--least-space table__cell--buttons">
														<button disabled={workingId === s.id} className={`button button--small ${s.authorized ? 'button--danger' : 'button--success'} button--icon button--icon-left button--loader`} onClick={() => this.handleAuthorizeClick(s.id)}>
															{workingId === s.id ? (
																<Loader className="button__loader" size="micro" foreground="#fff" background="rgba(255,255,255,.15)" />
															) : (
																s.authorized ? (
																	<IoMdThumbsDown className="button__icon" />
																) : (
																	<IoMdThumbsUp className="button__icon" />
																)
															)}
															{s.authorized ? 'Deauthorize' : 'Authorize'}
														</button>

														<button disabled={workingId === s.id} className={`button button--small ${s.development ? 'button--danger' : 'button--success'} button--icon button--icon-left button--loader`} onClick={() => this.handleDeveloperClick(s.id)}>
															{workingId === s.id ? (
																<Loader className="button__loader" size="micro" foreground="#fff" background="rgba(255,255,255,.15)" />
															) : (
																<IoMdBug className="button__icon" />
															)}
															{s.development ? 'Developer (OFF)' : 'Developer (ON)'}
														</button>

														<Link to={`/servers/labels/${s.id}`} className="button button--small button--primary button--icon button--icon-left">
															<IoMdPricetags className="button__icon" /> Labels
														</Link>
													</td>
												) : null}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)
					)}
				</section>
			);
		}

	}
);

export default ServerList;
