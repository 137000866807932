import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import ProgressBar from './ProgressBar';
import uTrackStore from '../../stores/uTrackStore';
import humanizeDuration from 'humanize-duration';
import Modal from 'react-modal';
import Loader from './Loader';

import { IoMdPlay, IoMdSpeedometer, IoMdHourglass, IoMdThermometer, IoMdArrowDown, IoMdArrowUp, IoMdBug, IoMdCube, IoMdArrowBack, IoMdClose, IoMdAlert } from 'react-icons/io';

Modal.setAppElement('#root');

const MonitoredInstance = observer(
	class MonitoredInstance extends Component {

		constructor(props) {
			super(props);

			this.state = {
				selectTaskModalOpen: false,
				taskId: ''
			};
		}

		handleInstanceClick = () => {
			const { instance: { server: { id } }, onHandleInstanceClick } = this.props;

			if (!this.state.selectTaskModalOpen) {
				onHandleInstanceClick(id);
			}
		}

		handleIncidentsClick = e => {
      e.stopPropagation();

      const { instance: { server: { id } } } = this.props;
      uTrackStore.loadServerIncidents(id)

      this.setState({
        incidentsModalOpen: true
      })
    }
    
    handleIncidentsModalClose = () => {
      this.setState({
        incidentsModalOpen: false
      })
    }

		handleExecuteTaskClick = e => {
			e.stopPropagation();

			uTrackStore.clearResult();
			uTrackStore.loadTasks();

			this.setState({
				selectTaskModalOpen: true
			});
    }
    
		handleTaskModalRequestClose = () => {
			this.setState({
				selectTaskModalOpen: false,
				taskId: ''
			});
		}

		handleTaskChange = e => {
			const taskId = e.target.value;

			this.setState({ taskId });
		}

		handleRunTask = () => {
			const { instance: { server: { id } } } = this.props;

			uTrackStore.executeTaskSync(id, this.state.taskId);
		}

		systemLoadClass = systemLoad => {
			const { monitor: { thresholds } } = uTrackStore;

			if (thresholds) {
				if (thresholds.loadGreenValue && systemLoad < thresholds.loadGreenValue) {
					return 'instance__load--green';
				}

				if (thresholds.loadGreenValue && thresholds.loadOrangeVlaue && systemLoad >= thresholds.loadGreenValue && systemLoad < thresholds.loadOrangeValue) {
					return 'instance__load--orange';
				}

				if (thresholds.loadRedValue && systemLoad >= thresholds.loadRedValue) {
					return 'instance__load--red';
				}
			}
		}

		convertUptime = seconds => {
			return humanizeDuration(seconds * 1000, {
				units: ['d', 'h', 'm'],
				round: true,
				spacer: '',
				language: 'shortEn',
				languages: {
					shortEn: {
						d: () => 'd',
						h: () => 'h',
						m: () => 'm'
					}
				}
			});
		}

		convertBytes = bytes => {
			var s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
			var e = Math.floor(Math.log(bytes) / Math.log(1024));

			return (bytes / Math.pow(1024, e)).toFixed(2) + " " + s[e];
		}

		render() {
			const { monitor: { thresholds }, tasks: { list: taskList, loading: tasksLoading, working: taskWorking }, incidents: { list: incidentList, loading: incidentsLoading }, result: { success, error, details } } = uTrackStore;
			const { instance, withIssue, expanded } = this.props;

			return (
				<div className={`instance${withIssue ? ' instance--issue' : ''}`} onClick={this.handleInstanceClick}>
					<div className="instance__header">
						<div className="instance__title">
							<h4 className="instance__hostname" title={instance.server.serialNumber}>
								{instance.server.development ? <IoMdBug className="instance__icon" title="Development server" /> : <IoMdCube className="instance__icon" />}
								{instance.server.hostname}
							</h4>
							<h5 className="instance__ip">{instance.server.ipAddress}</h5>
						</div>

						<div className="instance__load-uptime">
							<div className={`instance__load ${this.systemLoadClass(instance.stat.systemLoad)}`} title="System load">
								<IoMdSpeedometer className="instance__load-uptime-icon" />
								{instance.stat.systemLoad}
							</div>

							<div className="instance__uptime" title="System uptime">
								<IoMdHourglass className="instance__load-uptime-icon" />
								{this.convertUptime(instance.stat.systemUptime)}
							</div>
						</div>

						<div className="instance__controls">
							<button className="instance__control button button--danger" onClick={this.handleIncidentsClick}>
								<strong>{Object.keys(instance.identifierIncidents).length + Object.keys(instance.incidents).length}</strong> incident(s)
							</button>

							<button className="instance__control button button--primary button--icon button--icon-left" onClick={this.handleExecuteTaskClick}>
								<IoMdPlay className="button__icon" />
								Execute task
							</button>
						</div>
					</div>

					<div className={`instance__expand${expanded ? ' instance__expand--expanded' : ''}`}>
						<div className="instance__data">
							<div className="instance__memory">
								<ProgressBar
									className="instance__progress-bar"
									label="Memory"
									unit="MB"
									max={(instance.stat.memoryTotal / 1024 / 1024).toFixed(0)}
									value={(instance.stat.memoryFree / 1024 / 1024).toFixed(0)}
									indicatorType="percent"
									indicatorValues={{
										green: thresholds.memGreenValue,
										orange: thresholds.memOrangeValue,
										red: thresholds.memRedValue
									}}
								/>

								<ProgressBar
									className="instance__progress-bar"
									label="Swap"
									unit="MB"
									max={(instance.stat.swapTotal / 1024 / 1024).toFixed(0)}
									value={(instance.stat.swapUsed / 1024 / 1024).toFixed(0)}
								/>
							</div>

							<div className="instance__disks">
								{instance.stat.fileStores.map(fileStore => (
                  <div key={fileStore.name} className="instance__rwbox">
                    <div className="instance__rwbox-header">
                      <div className="instance__rwbox-left">{fileStore.model}</div>
                      <div className="instance__rwbox-right">{fileStore.name}</div>
                    </div>

                    <div className="instance__rwbox-stats">
											<div className="instance__rwbox-stat">
												<IoMdArrowDown className="instance__rwbox-icon" />

												<div className="instance__rwbox-values">
													<div className="instance__rwbox-value" title={`${fileStore.writeBytes} bytes`}>
														{this.convertBytes(fileStore.writeBytes)}
													</div>
                          <div className="instance__rwbox-rate">
                            {this.convertBytes(instance.hwDiskRates[fileStore.name].writeRate)}/s
                          </div>
												</div>
											</div>

											<div className="instance__rwbox-stat">
												<IoMdArrowUp className="instance__rwbox-icon" />

												<div className="instance__rwbox-values">
													<div className="instance__rwbox-value" title={`${fileStore.readBytes} bytes`}>
														{this.convertBytes(fileStore.readBytes)}
													</div>
                          <div className="instance__rwbox-rate">
                            {this.convertBytes(instance.hwDiskRates[fileStore.name].readRate)}/s
                          </div>
												</div>
											</div>
										</div>
                    
                    {fileStore.partitions.map(partition => (
                      <ProgressBar
                        key={partition.mount}
                        className="instance__progress-bar"
                        label={partition.mount}
                        unit="GB"
                        description={`name: ${partition.name}, fs type: ${partition.fsType}`}
                        max={(partition.totalSpace / 1024 / 1024 / 1024).toFixed(1)}
                        value={(partition.freeSpace / 1024 / 1024 / 1024).toFixed(1)}
                        indicatorType="percent"
                        indicatorValues={{
                          green: thresholds.diskGreenValue,
                          orange: thresholds.diskOrangeValue,
                          red: thresholds.diskRedValue
                        }}
                      />
                    ))}
                  </div>
								))}

								<ProgressBar
									className="instance__progress-bar"
									label="File descriptors"
									max={instance.stat.maxFdCount}
									value={instance.stat.openFdCount}
								/>
							</div>

							<div className="instance__network">
								{instance.stat.networkIFs.map(nif => (
									<div key={nif.name} className="instance__rwbox">
										<div className="instance__rwbox-header">
											<div className="instance__rwbox-left">{nif.name}</div>
											<div className="instance__rwbox-rigth">{nif.ipAddress}</div>
										</div>

										<div className="instance__rwbox-stats">
											<div className="instance__rwbox-stat">
												<IoMdArrowDown className="instance__rwbox-icon" />

												<div className="instance__rwbox-values">
													<div className="instance__rwbox-value" title={`${nif.byteRecv} bytes`}>
														{this.convertBytes(nif.byteRecv)}
													</div>
													{instance.networkIfRates[nif.name] ? (
														<div className="instance__rwbox-rate">
															{this.convertBytes(instance.networkIfRates[nif.name].inRate)}/s
														</div>
													) : null}
													<div className="instance__rwbox-errors">{nif.inErrors} errors</div>
												</div>
											</div>

											<div className="instance__rwbox-stat">
												<IoMdArrowUp className="instance__rwbox-icon" />

												<div className="instance__rwbox-values">
													<div className="instance__rwbox-value" title={`${nif.byteSent} bytes`}>
														{this.convertBytes(nif.byteSent)}
													</div>
													{instance.networkIfRates[nif.name] ? (
														<div className="instance__rwbox-rate">
															{this.convertBytes(instance.networkIfRates[nif.name].outRate)}/s
														</div>
													) : null}
													<div className="instance__rwbox-errors">{nif.outErrors} errors</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>

						<div className="instance__meta">
							<div className="instance__general">
								{instance.server.details.cpuName} (CPU &times; {instance.server.details.cpuNumber}, HDD &times; {instance.server.details.diskNumber}, RAM &times; ~{(instance.server.details.totalMemory / 1024 / 1024).toFixed(0)} MB)
							</div>

							<div className="instance__temp">
								<IoMdThermometer className="instance__temp-icon" />
								{instance.stat.cpuTemperature}&deg; C
							</div>
						</div>
					</div>

					{instance.server.labels && (instance.server.labels.country || instance.server.labels.region || instance.server.labels.comment) ? (
						<div className="instance__labels">
							{instance.server.labels.country ? <span className="instance__label">#{instance.server.labels.country}</span> : null}
							{instance.server.labels.region ? <span className="instance__label">#{instance.server.labels.region}</span> : null}
							{instance.server.labels.comment ? <span className="instance__label">#{instance.server.labels.comment}</span> : null}
						</div>
          ) : null}
          
          <Modal
						isOpen={this.state.incidentsModalOpen}
						bodyOpenClassName="--modal-open"
						overlayClassName="modal-overlay"
						className="modal modal--incidents"
						onRequestClose={this.handleIncidentsModalClose}
						shouldCloseOnOverlayClick={false}
						shouldCloseOnEsc={true}
					>
						<div className="modal__header">
							<h5 className="modal__title">
								<span className="modal__title-text">Incidents</span>
							</h5>

							<button className="button-close" onClick={this.handleIncidentsModalClose}>
								<IoMdClose className="button-close__icon" />
							</button>
						</div>

						<form className="form">
							<div className="modal__body">
              {incidentsLoading ? (
                <div className="incidents-loader__section">
                  <p className="section__text section__text--loader">
                    <Loader className="section__text-loader" size="tiny" />
                    Loading incidents, please wait ...
                  </p>
                </div>
              ) : incidentList ? (
                <table className="table incidents__table">
									<thead className="table__head">
										<tr>
											<th className="table__cell--least-space">Identifier</th>
											<th className="table__cell--least-space">Type</th>
											<th className="table__cell--least-space">Started At</th>
                      <th className="table__cell--least-space">Start Value</th>
										</tr>
									</thead>
									<tbody>
                    {incidentList.map(incident => (
                      <tr key={incident.id}>
                        <td className="table__cell--least-space">{incident.identifier}</td>
                        <td className="table__cell--least-space">{incident.incidentType}</td>
                        <td className="table__cell--least-space">{new Date(incident.startedAt).toLocaleDateString('en-GB')}<br />
                            {new Date(incident.startedAt).toLocaleTimeString()}</td>
                        <td className="table__cell--least-space">{incident.startValue || '/'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
							</div>
						</form>
					</Modal>

					<Modal
						isOpen={this.state.selectTaskModalOpen}
						bodyOpenClassName="--modal-open"
						overlayClassName="modal-overlay"
						className="modal modal--task-selector"
						onRequestClose={this.handleTaskModalRequestClose}
						shouldCloseOnOverlayClick={false}
						shouldCloseOnEsc={true}
					>
						<div className="modal__header">
							<h5 className="modal__title">
								<IoMdPlay className="modal__title-icon" />
								<span className="modal__title-text">{success ? 'Result' : 'Task selection'}</span>
							</h5>

							<button className="button-close" onClick={this.handleTaskModalRequestClose}>
								<IoMdClose className="button-close__icon" />
							</button>
						</div>

						<form className="form">
							<div className="modal__body">
								{!success ? (
									<Fragment>
										<p className="modal__text">Please select a task to run on this server below.</p>

										{error ? (
											<div className="message message--error">
												<IoMdAlert className="message__icon" /> {error}
											</div>
										) : null}

										{tasksLoading ? (
											<p className="section__text section__text--loader">
												<Loader className="section__text-loader" size="tiny" />
												Loading tasks, please wait ...
											</p>
										) : (
											taskList.length > 0 ? (
												<div className="form__group form__group--no-margin">
													<label htmlFor="taskId" className="form__label">Task</label>
													<select id="taskId" className="form__select" value={this.state.taskId} onChange={this.handleTaskChange}>
														<option>Select task ...</option>
														{taskList.map(t => (
															<option key={t.id} value={t.id}>{t.name}</option>
														))}
													</select>
												</div>
											) : (
												<p className="modal__text">There are no tasks that can be executed on this server.</p>
											)
										)}
									</Fragment>
								) : (
									details ? (
										<div className="task-output">
											<div className="task-output__section">
												<h6 className="task-output__section-title">
													<span className="task-output__section-title-text">Main script</span>
													<span className="task-output__section-title-meta">Return value: {details.mainScriptResult} in {details.mainExecutionTime} ms</span>
												</h6>

												<div className="task-output__text">{details.mainScriptOutput ? details.mainScriptOutput : '<empty>'}</div>
											</div>

											{details.cleanupScriptResult !== null ? (
												<div className="task-output__section">
													<h6 className="task-output__section-title">
														<span className="task-output__section-title-text">Cleanup script</span>
														<span className="task-output__section-title-meta">Return value: {details.cleanupScriptResult} in {details.cleanupExecutionTime} ms</span>
													</h6>

													<div className="task-output__text">{details.cleanupScriptOutput ? details.cleanupScriptOutput : '<empty>'}</div>
												</div>
											) : null}

											{details.rollbackScriptResult !== null ? (
												<div className="task-output__section">
													<h6 className="task-output__section-title">
														<span className="task-output__section-title-text">Rollback script</span>
														<span className="task-output__section-title-meta">Return value: {details.rollbackScriptResult} in {details.rollbackExecutionTime} ms</span>
													</h6>

													<div className="task-output__text">{details.rollbackScriptOutput ? details.rollbackScriptOutput : '<empty'}</div>
												</div>
											) : null}
										</div>
									) : null
								)}
							</div>

							{!success ? (
								<div className="modal__footer">
									<div className="modal__buttons">
										<button className="button button--secondary button--icon button--icon-left" onClick={this.handleTaskModalRequestClose}>
											<IoMdArrowBack className="button__icon" /> Cancel
										</button>

										<button disabled={taskWorking || !this.state.taskId} type="submit" className="button button--danger button--icon button--icon-left button--loader" onClick={this.handleRunTask}>
											{taskWorking ? (
												<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
											) : (
												<IoMdPlay className="button__icon" />
											)}
											Run task
										</button>
									</div>
								</div>
							) : null}
						</form>
					</Modal>
				</div>
			);
		}

	}
);

MonitoredInstance.defaultProps = {
	withIssue: false
};

export default MonitoredInstance;
