import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import { Link } from 'react-router-dom';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

import { IoMdAdd, IoMdPlay, IoMdCreate, IoMdTrash } from 'react-icons/io';

const TaskList = observer(
	class TaskList extends Component {

		componentDidMount() {
			uTrackStore.loadServers();
			uTrackStore.loadTasks();
		}

		handleDeleteClick = async id => {
			if (window.confirm(`Are you sure you want to delete this task?`)) {
				await uTrackStore.deleteTask(id);

				uTrackStore.loadTasks();
			}
		}

		render() {
			const { isAdmin, servers: { list: serverList }, tasks: { list, loading, workingId } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Tasks</h2>
					</div>

					<ResultMessage />

					{isAdmin ? (
						<div className="section__buttons section__buttons--right">
							<Link to="/tasks/create" className="button button--primary button--icon button--icon-left">
								<IoMdAdd className="button__icon" /> Create task
							</Link>
						</div>
					) : null}

					{loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading tasks, please wait ...
							</p>
						</div>
					) : (
						list.length === 0 ? (
							<div className="section__content">
								<p className="section__text">There are no tasks to show.</p>
							</div>
						) : (
							<div className="section__table">
								<table className="table">
									<thead className="table__head">
										<tr>
											<th>Name</th>
											<th>Monitored</th>
											<th>Frequency</th>
											<th>Run on</th>
											<th className="table__cell--least-space">Action</th>
										</tr>
									</thead>

									<tbody>
										{list.map(t => (
											<tr key={t.id}>
												<td>{t.name}</td>
												<td>{t.monitored ? 'Yes' : 'No'}</td>
												<td>{t.frequency} ms</td>
												<td>
													{t.runOnAll ? 'All servers' : serverList.find(s => s.id === t.runOnServer).hostname}
												</td>
												<td className="table__cell--least-space table__cell--buttons">
													<Link to={`/tasks/execute/${t.id}`} className="button button--small button--danger button--icon button--icon-left">
														<IoMdPlay className="button__icon" /> Execute
													</Link>

													{isAdmin ? (
														<Fragment>
															<Link to={`/tasks/edit/${t.id}`} className="button button--small button--primary button--icon button--icon-left">
																<IoMdCreate className="button__icon" /> Edit
															</Link>

															<button disabled={workingId === t.id} className="button button--small button--danger button--icon button--icon-left" onClick={() => this.handleDeleteClick(t.id)}>
																{workingId === t.id ? (
																	<Loader className="button__loader" size="micro" foreground="#fff" background="rgba(255,255,255,.15)" />
																) : (
																	<IoMdTrash className="button__icon" />
																)}
																Delete
															</button>
														</Fragment>
													) : null}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)
					)}
				</section>
			);
		}

	}
);

export default TaskList;
