import React, { PureComponent } from 'react';

import { IoMdSearch } from 'react-icons/io';

class HTTP404 extends PureComponent {

	render() {
		return (
			<div className="http-error">
				<div className="http-error__content">
					<IoMdSearch className="http-error__icon" />

					<h2 className="http-error__title">HTTP 404</h2>

					<p className="http-error__text">The requested page cannot be found.</p>
				</div>
			</div>
		);
	}

}

export default HTTP404;
