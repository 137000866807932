import axios from 'axios';
import Qs from 'qs';
import Config from '../config/Config';

class uTrackAPI {

	uTrackStore;
	axiosInstance;

	constructor(uTrackStore) {
		this.uTrackStore = uTrackStore;
		this.axiosInstance = axios.create();

		this.axiosInstance.interceptors.request.use(config => {
			const { token } = this.uTrackStore;

			if (!!token) {
				config.headers = {
					...config.headers,
					'Authorization': token
				};		
			}

			return config;
		});

		this.axiosInstance.interceptors.response.use(response => {
			return response;
		}, error => {
			if (error && error.response && error.response.status === 401) {
				if (this.uTrackStore.isAuthenticated) {
					this.uTrackStore.logout();
				}
			}

			return Promise.reject(error);
		});
	}

	// End points

	user() {
		this.axiosInstance.defaults.baseURL = Config.API_URL + '/@user';

		return this;
	}

	admin() {
		this.axiosInstance.defaults.baseURL = Config.API_URL + '/@admin';

		return this;
	}

	// API methods

	get(url, params = null) {
		if (params === null) {
			return this.axiosInstance.get(url);
		} else {
			return this.axiosInstance.get(url, {
				params: params,
				paramsSerializer: params => Qs.stringify(params, {
					arrayFormat: 'repeat'
				})
			});
		}
	}

	post(url, data = {}, upload = false, onProgress = null, config = {}) {
		if (upload) {
			config.headers = {
				...config.headers,
				'Content-Type': 'multipart/form-data'
			};

			if (!!onProgress) {
				config.onUploadProgress = onProgress;
			}
		}

		return this.axiosInstance.post(url, data, config);
	}

	put(url, data = {}) {
		return this.axiosInstance.put(url, data);
	}

	delete(url) {
		return this.axiosInstance.delete(url);
	}

	// API calls
	
	login(username, password) {
		const data = new FormData();

		data.append('username', username);
		data.append('password', password);

		return this.user().post('/login', data);
	}

	self() {
		return this.user().get('/self');
	}

	logout() {
		return this.user().post('/logout');
	}

	loadAccountDetails() {
		return this.user().get('/account/details');
	}

	updateAccountDetails(data) {
		return this.user().put('/account/details', data);
	}

	changePassword(data) {
		return this.user().put('/account/password', data);
	}

	loadLDAPUsers() {
		return this.admin().get('/ldap/user');
	}

	createLDAPUser(data) {
		return this.admin().post('/ldap/user', data);
	}

	updateLDAPUser(username, data) {
		return this.admin().put(`/ldap/user/${username}`, data);
	}

	changeLDAPUserPassword(username, data) {
		return this.admin().put(`/ldap/password/${username}`, data);
	}

	deleteLDAPUser(username) {
		return this.admin().delete(`/ldap/user/${username}`);
	}

	loadMonitoredInstances() {
		return this.user().get('/monitor/instances');
	}

	loadMonitorThresholds() {
		return this.user().get('/monitor/thresholds');
	}

	loadServers() {
		return this.user().get('/api/servers');
	}

	authorizeServer(id, value) {
		return this.admin().put(`/api/servers/authorize/${id}/${value}`);
	}

	changeServerDevelopmentFlag(id, value) {
		return this.admin().put(`/api/servers/development/${id}/${value}`);
	}

	labelServer(id, data) {
		return this.admin().put(`/api/servers/label/${id}`, data);
	}

	loadTasks() {
		return this.user().get('/api/tasks');
	}

	loadTask(id) {
		return this.user().get(`/api/tasks/${id}`);
	}

	createTask(data) {
		return this.admin().post('/api/tasks', data);
	}

	updateTask(id, data) {
		return this.admin().put(`/api/tasks/${id}`, data);
	}

	deleteTask(id) {
		return this.admin().delete(`/api/tasks/${id}`);
	}

	executeTaskSync(serverId, taskId) {
		return this.user().get(`/api/tasks/server/execute/${serverId}/${taskId}`);
	}

	executeTaskAsync(id, data) {
		return this.user().post(`/api/tasks/execute/${id}`, data);
	}

	loadTasksStatus() {
		return this.user().get('/api/tasks/log/status');
	}

	loadTaskLogGroups(page) {
		return this.user().get(`/api/tasks/log/groups?limit=${Config.PAGING_PER_PAGE}&offset=${page * Config.PAGING_PER_PAGE}`)
	}

	viewTaskLogGroup(id) {
		return this.user().get(`/api/tasks/log/groups/${id}`);
  }
  
  loadServerIncidents(serverId) {
    return this.user().get(`/monitor/incidents/active/${serverId}`);
  }

	loadSettings(page) {
		return this.admin().get(`/api/settings?limit=${Config.PAGING_PER_PAGE}&offset=${page * Config.PAGING_PER_PAGE}`);
	}

	loadSetting(id) {
		return this.admin().get(`/api/settings/${id}`);
	}

	createSetting(data) {
		return this.admin().post('/api/settings', data);
	}

	updateSetting(id, data) {
		return this.admin().put(`/api/settings/${id}`, data);
	}

	deleteSetting(id) {
		return this.admin().delete(`/api/settings/${id}`);
	}

}

export default uTrackAPI;
