import React, { Component } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

const Password = observer(
	class Password extends Component {

		constructor(props) {
			super(props);

			this.oldPasswordRef = React.createRef();
		}

		componentDidMount() {
			runInAction(() => {
				uTrackStore.user.oldPassword = '';
				uTrackStore.user.newPassword = '';
			});

			if (this.oldPasswordRef.current) {
				this.oldPasswordRef.current.focus();
			}
		}

		handleOldPasswordChange = e => {
			uTrackStore.user.oldPassword = e.target.value;
		}

		handleNewPasswordChange = e => {
			uTrackStore.user.newPassword = e.target.value;
		}

		handleChangePasswordClick = async e => {
			e.preventDefault();

			await uTrackStore.changePassword();
		}

		render() {
			const { user: { oldPassword, newPassword, working } } = uTrackStore;

			return (
				<section className="section account">
					<h2 className="section__title">Change password</h2>

					<div className="section__content">
						<p className="section__text">To change your account's password please fill out the form below.</p>

						<ResultMessage />

						<form className="form">
							<div className="form__group">
								<label htmlFor="oldPassword" className="form__label">Old password</label>
								<input ref={this.oldPasswordRef} type="password" id="oldPassword" className="form__input form__input--short" placeholder="Old password ..." onChange={this.handleOldPasswordChange} value={oldPassword} />
							</div>

							<div className="form__group">
								<label htmlFor="newPassword" className="form__label">New password</label>
								<input type="password" id="newPassword" className="form__input form__input--short" placeholder="New password ..." onChange={this.handleNewPasswordChange} value={newPassword} />
							</div>

							<div className="form__buttons">
								<button disabled={working || !oldPassword || !newPassword} className="button button--primary button--loader" onClick={this.handleChangePasswordClick}>
									{working ? (
										<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
									) : null}
									Change password
								</button>
							</div>
						</form>
					</div>
				</section>
			);
		}

	}
);

export default Password;
