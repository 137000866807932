import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

import { IoMdEye, IoMdAdd, IoMdTrash } from 'react-icons/io';

const LDAPUsers = observer(
	class LDAPUsers extends Component {

		componentDidMount() {
			uTrackStore.loadLDAPUsers();
		}

		handleDeleteClick = async username => {
			if (window.confirm(`Are you sure you want to delete LDAP user '${username}?`)) {
				await uTrackStore.deleteLDAPUser(username);

				uTrackStore.loadLDAPUsers();
			}
		}

		render() {
			const { ldap: { users, loading, workingId } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">LDAP users</h2>
					</div>

					<ResultMessage />

					<div className="section__buttons section__buttons--right">
						<Link to="/ldap/users/create" className="button button--primary button--icon button--icon-left">
							<IoMdAdd className="button__icon" /> Create LDAP user
						</Link>
					</div>

					{loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading LDAP users, please wait ...
							</p>
						</div>
					) : (
						users.length === 0 ? (
							<div className="section__content">
								<p className="section__text">There are no LDAP users to show.</p>
							</div>
						) : (
							<div className="section__table">
								<table className="table">
									<thead className="table__head">
										<tr>
											<th>Display name / Username</th>
											<th>Name</th>
											<th>E-mail</th>
											<th>Phone number</th>
											<th className="table__cell--least-space">Action</th>
										</tr>
									</thead>

									<tbody>
										{users.map(u => (
											<tr key={u.username}>
												<td>
													{u.displayName}
													<br />
													{u.username}
												</td>
												<td>{u.firstName} {u.lastName}</td>
												<td>{u.email}</td>
												<td>{u.phoneNumber}</td>
												<td className="table__cell--least-space table__cell--buttons">
													<Link to={`/ldap/users/view/${u.username}`} className="button button--small button--primary button--icon button--icon-left">
														<IoMdEye className="button__icon" /> View
													</Link>

													<button disabled={workingId === u.username} className="button button--small button--danger button--icon button--icon-left" onClick={() => this.handleDeleteClick(u.username)}>
														{workingId === u.username ? (
															<Loader className="button__loader" size="micro" foreground="#fff" background="rgba(255,255,255,.15)" />
														) : (
															<IoMdTrash className="button__icon" />
														)}
														Delete
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)
					)}
				</section>
			);
		}

	}
);

export default LDAPUsers;
