import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import uTrackStore from '../../stores/uTrackStore';

class Logout extends Component {

	constructor(props) {
		super(props);

		this.mounted = false;
		this.state = {
			signedOut: false
		};
	}

	componentDidMount() {
		this.mounted = true;

		uTrackStore.logout().then(() => {
			if (this.mounted) {
				this.setState({
					signedOut: true
				});
			}
		});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		return (
			this.state.signedOut ? <Redirect to="/" /> : null
		);
	}

}

export default Logout;
