import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import Loader from '../base/Loader';
import ResultMessage from '../base/ResultMessage';

const TaskLogView = observer(
	class TaskLogView extends Component {

		componentDidMount() {
			const { id } = this.props.match.params;

			uTrackStore.viewTaskLogGroup(id).then(result => {
				uTrackStore.loadTasksStatus();

				if (!result) {
					uTrackStore.preserveResult();

					this.props.history.push('/tasks/log');
				}
			});
		}

		render() {
			const { tasks: { taskLogGroupData, loading } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">Task log</h2>
					</div>

					<ResultMessage />

					{!taskLogGroupData || loading ? (
						<div className="section__content">
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading task log, please wait ...
							</p>
						</div>
					) : (
						<div className="section__content">
							<div className="task-info">
								<h4 className="task-info__title">Script name: {taskLogGroupData.taskName}</h4>
							</div>

							{taskLogGroupData.finished ? (
								taskLogGroupData.items.map(item => (
									<div key={item.server.id} className="task-output">
										<h5 className="task-output__server">{item.server.hostname}</h5>

										<div className="task-output__section">
											<h6 className="task-output__section-title">
												<span className="task-output__section-title-text">Main script</span>
												<span className="task-output__section-title-meta">Return value: {item.mainResult} in {item.mainTime} ms</span>
											</h6>

											<div className="task-output__text">{item.mainOutput ? item.mainOutput : '<empty>'}</div>
										</div>

										{item.cleanupResult !== null ? (
											<div className="task-output__section">
												<h6 className="task-output__section-title">
													<span className="task-output__section-title-text">Cleanup script</span>
													<span className="task-output__section-title-meta">Return value: {item.cleanupResult} in {item.cleanupTime} ms</span>
												</h6>

												<div className="task-output__text">{item.cleanupOutput ? item.cleanupOutput : '<empty>'}</div>
											</div>
										) : null}

										{item.rollbackResult !== null ? (
											<div className="task-output__section">
												<h6 className="task-output__section-title">
													<span className="task-output__section-title-text">Rollback script</span>
													<span className="task-output__section-title-meta">Return value: {item.rollbackResult} in {item.rollbackTime} ms</span>
												</h6>

												<div className="task-output__text">{item.rollbackOutput ? item.rollbackOutput : '<empty'}</div>
											</div>
										) : null}
									</div>
								))
							) : (
								<p class="section__text">This task has not finished yet, please check later.</p>
							)}
						</div>
					)}
				</section>
			);
		}

	}
);

export default TaskLogView;
