import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import uTrackStore from './stores/uTrackStore';
import Loading from './components/loading/Loading';
import Main from './components/base/Main';

const App = observer(
	class App extends Component {

		render() {
			const { isAppReady } = uTrackStore;

			return (
				isAppReady ? (
					<BrowserRouter basename={process.env.PUBLIC_URL}> 
						<Main />
					</BrowserRouter>
				) : (
					<Loading />
				)
			);
		}
	}
);

export default App;
