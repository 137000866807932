import React, { Component } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import uTrackStore from '../../stores/uTrackStore';
import ResultMessage from '../base/ResultMessage';
import Loader from '../base/Loader';

const TaskForm = observer(
	class TaskForm extends Component {

		constructor(props) {
			super(props);

			this.nameRef = React.createRef();
		}

		componentDidMount() {
			uTrackStore.clearResult();

			runInAction(() => {
				uTrackStore.tasks.name = '';
				uTrackStore.tasks.mainScript = '';
				uTrackStore.tasks.cleanupScript = '';
				uTrackStore.tasks.rollbackScript = '';
				uTrackStore.tasks.monitored = 0;
				uTrackStore.tasks.frequency = 0;
				uTrackStore.tasks.runOnAll = 0;
				uTrackStore.tasks.runOnServer = '';
			});

			const { id } = this.props.match.params;

			if (id) {
				uTrackStore.loadTask(id).then(result => {
					if (!result) {
						uTrackStore.preserveResult();
	
						this.props.history.push('/tasks');
					}
				});
			}

			uTrackStore.loadServers();

			if (this.nameRef.current) {
				this.nameRef.current.focus();
			}
		}

		handleNameChange = e => {
			uTrackStore.tasks.name = e.target.value;
		}

		handleMainScriptChange = e => {
			uTrackStore.tasks.mainScript = e.target.value;
		}

		handleCleanupScriptChange = e => {
			uTrackStore.tasks.cleanupScript = e.target.value;
		}

		handleRollbackScriptChange = e => {
			uTrackStore.tasks.rollbackScript = e.target.value;
		}

		handleMonitoredChange = e => {
			uTrackStore.tasks.monitored = e.target.checked ? 1 : 0;
		}

		handleFrequencyChange = e => {
			uTrackStore.tasks.frequency = e.target.value;
		}

		handleRunOnAllChange = e => {
			uTrackStore.tasks.runOnAll = e.target.checked ? 1 : 0;
		}

		handleRunOnServerChange = e => {
			uTrackStore.tasks.runOnServer = e.target.value;
		}

		handleSubmitClick = async e => {
			e.preventDefault();

			const { id } = this.props.match.params;
			
			if (id) {
				await uTrackStore.updateTask(id);
			} else {
				await uTrackStore.createTask();
			}

			const { result: { success } } = uTrackStore;

			if (success) {
				uTrackStore.preserveResult();

				this.props.history.push('/tasks');
			}
		}

		render() {
			const { id } = this.props.match.params;
			const { servers: { list }, tasks: { name, mainScript, cleanupScript, rollbackScript, monitored, frequency, runOnAll, runOnServer, loading, working } } = uTrackStore;

			return (
				<section className="section account">
					<div className="section__header">
						<h2 className="section__title">
							{id ? 'Edit task' : 'Create task'}
						</h2>
					</div>

					<div className="section__content">
						<p className="section__text">
							{id ? 'To edit task please fill out the form below.' : 'To create new task please fill out the form below.'}</p>

						<ResultMessage />

						{loading ? (
							<p className="section__text section__text--loader">
								<Loader className="section__text-loader" size="tiny" />
								Loading task, please wait ...
							</p>
						) : (
							<form className="form">
								<div className="form__group">
									<label htmlFor="name" className="form__label">Name</label>
									<input ref={this.nameRef} type="text" id="name" className="form__input form__input--short" placeholder="Task name ..." onChange={this.handleNameChange} value={name} />
								</div>

								<div className="form__row">
									<div className="form__column">
										<div className="form__group">
											<label htmlFor="mainScript" className="form__label">Main script</label>
											<textarea rows="5" id="mainScript" className="form__input form__input--monospace" placeholder="Main script ..." onChange={this.handleMainScriptChange} value={mainScript} />
										</div>

										<div className="form__group">
											<label htmlFor="cleanupScript" className="form__label">Cleanup script (optional)</label>
											<textarea rows="5" id="cleanupScript" className="form__input form__input--monospace" placeholder="Cleanup script ..." onChange={this.handleCleanupScriptChange} value={cleanupScript} />
										</div>

										<div className="form__group">
											<label htmlFor="rollbackScript" className="form__label">Rollback script</label>
											<textarea rows="5" id="rollbackScript" className="form__input form__input--monospace" placeholder="Rollback script ..." onChange={this.handleRollbackScriptChange} value={rollbackScript} />
										</div>
									</div>

									<div className="form__column">
										<div className="form__group">
											<label htmlFor="monitored" className="form__label">Monitored</label>
											<label className="form__checkbox-wrap">
												<input type="checkbox" id="monitored" className="form__checkbox" checked={monitored} onChange={this.handleMonitoredChange} />
												<span className="form__checkbox-text">This task is monitored</span>
											</label>
										</div>

										{monitored ? (
											<div className="form__group">
												<label htmlFor="frequency" className="form__label">Frequency (ms)</label>
												<input type="number" min={0} step={1000} id="frequency" className="form__input form__input--short" placeholder="Task frequency ..." onChange={this.handleFrequencyChange} value={frequency} />
											</div>
										) : null}

										<div className="form__group">
											<label htmlFor="runOnAll" className="form__label">Run on all servers</label>
											<label className="form__checkbox-wrap">
												<input type="checkbox" id="runOnAll" className="form__checkbox" checked={runOnAll} onChange={this.handleRunOnAllChange} />
												<span className="form__checkbox-text">This task is to be executed on all servers</span>
											</label>
										</div>

										{!runOnAll ? (
											<div className="form__group">
												<label htmlFor="runOnServer" className="form__label">Run on server</label>
												<select id="runOnServer" className="form__select" value={runOnServer} onChange={this.handleRunOnServerChange}>
													<option>Select server ...</option>
													{list.map(s => (
														<option key={s.id} value={s.id}>{s.hostname ? s.hostname : '(empty)'}</option>
													))}
												</select>
											</div>
										) : null}
									</div>
								</div>

								<div className="form__buttons">
									<button disabled={working || !name || !mainScript || (monitored && frequency === '') || (!runOnAll && !runOnServer)} className="button button--primary button--loader" onClick={this.handleSubmitClick}>
										{working ? (
											<Loader className="button__loader" size="tiny" foreground="#fff" background="rgba(255,255,255,.15)" />
										) : null}
										{id ? 'Save changes' : 'Create task'}
									</button>
								</div>
							</form>
						)}
					</div>
				</section>
			);
		}

	}
);

export default TaskForm;
