import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import uTrackStore from '../../stores/uTrackStore';

import { IoMdHome, IoMdCog, IoMdKey, IoMdList, IoMdPlay } from 'react-icons/io';

const Sidebar = observer(
	class Sidebar extends Component {

		render() {
			const { isAdmin } = uTrackStore;

			return (
				<aside className="sidebar">
					<ul className="sidebar__list">
						<li className="sidebar__item">
							<NavLink exact to="/" className="sidebar__link" activeClassName="sidebar__link--active">
								<IoMdHome className="sidebar__icon" /> Dashboard
							</NavLink>
						</li>

						<li className="sidebar__spacer" />

						{isAdmin ? (
							<Fragment>
								<li className="sidebar__item">
									<NavLink to="/settings" className="sidebar__link" activeClassName="sidebar__link--active">
										<IoMdCog className="sidebar__icon" /> Settings
									</NavLink>
								</li>

								<li className="sidebar__item">
									<NavLink to="/ldap/users" className="sidebar__link" activeClassName="sidebar__link--active">
										<IoMdKey className="sidebar__icon" /> LDAP database
									</NavLink>
								</li>
							</Fragment>
						) : null}
						
						<li className="sidebar__item">
							<NavLink to="/servers" className="sidebar__link" activeClassName="sidebar__link--active">
								<IoMdList className="sidebar__icon" /> Servers
							</NavLink>
						</li>

						<li className="sidebar__item">
							<NavLink to="/tasks" className="sidebar__link" activeClassName="sidebar__link--active">
								<IoMdPlay className="sidebar__icon" /> Tasks
							</NavLink>
						</li>
					</ul>
				</aside>
			);
		}

	}
);

export default Sidebar;
